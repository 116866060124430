<app-loading-spinner *ngIf="loading"></app-loading-spinner>

<div class="event-infos-container" *ngIf="!loading">
  <div class="modal-header">
    <div class="close-modal pointer" (click)="handleBackToList()">
      <i class="bi bi-x-lg"></i>
    </div>
  </div>

  <div class="modal-sections row">
    <section class="left-side-section col-12 col-sm-12 col-md-12 col-lg-8">
      <div class="modal-event-background" [ngStyle]="{ 'background-image': 'url(' + event.imageLink + ')' }">
        <h4 class="modal-event-title">{{ event.name }}</h4>
      </div>

      <div class="d-flex justify-content-end">

        <div class="save-share-options" *ngIf="userProfile?.idUser === event.organizer.idUser && verifyUserCanCreate()">
          <button class="custom-button-border" style="font-size: 1.2rem;" (click)="openEditEvent()">
            <i class="bi bi-pen me-2"></i>
          </button>
          <button class="custom-button-border-red" style="font-size: 1.2rem;" (click)="openDeleteEventModal()">
            <i class="bi bi-trash danger mr-2"></i>
          </button>
        </div>
        <div class="save-share-options">
          <button class="custom-button-border" style="font-size: 1.2rem;" (click)="shareEvent()">
            <i class="bi bi-share me-2"></i>
          </button>
        </div>
      </div>

      <div class="show-on-mobile">
        <div class="d-flex flex-column gap-4">

          <div *ngTemplateOutlet="eventInfoTemplate; context: { event: event }"></div>
          <div *ngTemplateOutlet="eventTicketsTemplate; context: { event: event }"></div>
        </div>
      </div>

      <div class="modal-event-description my-4">
        <h5 class="custom-modal-title">Descrição</h5>
        <span class="description-text" [innerHTML]="sanitizedContentDescription"> </span>
      </div>

      <div *ngIf="this.event.about !== ''" class="modal-event-description mb-4">
        <h5 class="custom-modal-title">Políticas do evento</h5>
        <div class="description-text" [innerHTML]="sanitizedContentRules">
        </div>
      </div>
    </section>

    <section class="right-side-section col-12 col-sm-12 col-md-12 col-lg-4">

      <div class="hide-on-mobile">
        <div class="d-flex flex-column gap-4">

          <div *ngTemplateOutlet="eventInfoTemplate; context: { event: event }"></div>
          <div *ngTemplateOutlet="eventTicketsTemplate; context: { event: event }"></div>
        </div>
      </div>

      <div class="event-connections">
        <h5 class="connections-title">Participates neste evento</h5>

        <div class="community-members-cards" *ngIf="participants.length"
          [ngStyle]="{'padding-right': seeMoreCommunityMembers ? '10px' : '20px'}">
          <div class="community-members-card pointer"
            *ngFor="let participant of participants | slice:0:seeMoreCommunityMembers ? participants.length : 3; let i = index">
            <div class="comment-information" (click)="openParticipantProfile(participant)">
              <div class="comment-information-left">
                <div class="comment-information-img">
                  <img [src]="participant.uriImageProfile ? participant.uriImageProfile : defaultImageProfile"
                    alt="participant-profile-pic" class="profile-pic">
                </div>
              </div>
              <div class="comment-information-center">
                <div class="comment-information-info">
                  <span class="comment-information-name">{{ participant.name }}</span>
                  <span class="comment-information-date multi-line"
                    [innerHTML]="participant.bio">{{participant.bio}}</span>
                </div>
              </div>
            </div>
            <div class="participant-checker" (click)="checkinParticipant(participant)"
              *ngIf="userProfile?.idUser === event.organizer.idUser">
              <i class="bi bi-check-circle-fill " [ngStyle]="{'color': participant.checkIn ? 'green' : 'gray'}"></i>
            </div>
          </div>
        </div>
        <div class="mt-3 px-4" *ngIf="!participants.length">
          <p translate>Nenhuma conexão encontrada</p>
        </div>
        <div class="separator"></div>

        <div class="see-more-members d-flex justify-content-end" *ngIf="participants.length > 3">
          <button class="custom-button-no-border" (click)="seeMoreCommunityMembers = !seeMoreCommunityMembers">
            {{
            seeMoreCommunityMembers ? 'Ver menos' : 'Ver mais (' + (participants.length - 3) + ')'
            }}
          </button>
        </div>
      </div>
    </section>
  </div>
</div>

<ng-template #eventInfoTemplate let-event="event">
  <div class="modal-event-infos">
    <h5 class="custom-modal-title">Informações</h5>
    <span>Data: {{ event.startDate | date: 'dd/MM/yyyy' }} á {{ event.endDate | date: 'dd/MM/yyyy' }}</span>
    <span>Horário: {{ event.startDate | date: 'HH:mm' }} até {{ event.endDate | date: 'HH:mm' }}</span>

    <span *ngIf="event.type !== 'REMOTO'">
      Local: {{ event.address }}, Nº {{ event.number }}, {{ event.neighborhood }}, {{ event.city }} - {{ event.state }},
      {{ event.zipCode }}, {{ event.country }}
    </span>

    <span *ngIf="event.type === 'REMOTO'">
      Link: <a [href]="getWebsiteUrl(event.website)" target="_blank" rel="noopener noreferrer">
        {{ event.website }}
      </a>
    </span>

    <div class="see-on-map" *ngIf="event.address">
      <a class="custom-button-border" [href]="'https://www.google.com/maps/?q=' + getAddress()" target="_blank"
        rel="noopener noreferrer">
        <i class="bi bi-map"></i> Ver no Mapa
      </a>
    </div>
  </div>
</ng-template>

<ng-template #eventTicketsTemplate let-event="event">
  <div class="event-tickets">
    <div class="tickets-header">
      <h5 class="tickets-title">Ingressos</h5>
      <div class="">
        <i class="bi bi-info-circle me-2"></i>
        <span class="tickets-info-text">Info sobre taxas</span>
      </div>
    </div>

    <div class="tickets-items">
      <div class="d-flex flex-direction-column">
        <span class="tickets-quantity-title">Quantidade de participantes</span>
        <span class="tickets-quantity-text">{{ registeredCount.toString() }}</span>
        <span class="tickets-validity">Válido até {{ event.endDate | date: 'dd/MM/yyyy' }}</span>
      </div>
      <div class="d-flex flex-direction-column">
        <button *ngIf="!isUserJoined()" [disabled]="joiningLoading || missingParticipants < 1 " (click)="joinEvent()">
          <div class="button-load-spinner" *ngIf="joiningLoading"></div>
          {{ joiningLoading ? '' : 'Participar'}}
        </button>
      </div>
    </div>
  </div>
</ng-template>