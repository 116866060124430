<app-loading-spinner [bgTransparent]="true" *ngIf="loading"></app-loading-spinner>

<app-manager-company-advertisement *ngIf="screens === 'company-advertisement-screen'"
  (closeCompanyAdvertisement)="handleCloseScreens()"
  (submittedCompanyAdvertisement)="handleSubmittedCompanyAdvertisement()" [advertisementId]="selectedAdvertisementId" />

<app-contact-announcement *ngIf="screens === 'contact-screen' && selectedAdvertisementId"
  [advertisementId]="selectedAdvertisementId" (closeScreen)="handleCloseScreens()" />

<div class="showcase" *ngIf="screens === 'store-screen'">
  <div class="page-title">
    <div class="d-flex align-items-center">
      <i class="bi bi-chevron-left"></i>
      <h4 id="mention-title">Vitrines</h4>
    </div>
    <button class="highlight-button" (click)="handleOpenCompanyAdvertisement()">Divulgar Empresa</button>
  </div>

  <div class="stats">
    <div class="stat-item">
      <div class="stat-item-left">
        <img src="assets/img/store-card-people-icon.png" alt="Ícone de pessoas">

      </div>
      <div class="stat-item-right">
        <span>Qtd. de Anúncios</span>
        <h4>{{ storeStats.totalAdvertisements || 0 }}</h4>
      </div>
    </div>
    <div class="stat-item">
      <div class="stat-item-left">
        <img src="assets/img/store-card-people-icon.png" alt="Ícone de pessoas">
      </div>
      <div class="stat-item-right">
        <span>Qtd. de Anúncios Premium</span>
        <h4>{{ storeStats.premiumAdvertisements || 0 }}</h4>
      </div>
    </div>
    <div class="stat-item">
      <div class="stat-item-left">
        <img src="assets/img/store-card-people-icon.png" alt="Ícone de pessoas">
      </div>
      <div class="stat-item-right">
        <span>Nº de Contatos</span>
        <h4>{{ storeStats.totalContacts || 0 }}</h4>
      </div>
    </div>
    <div class="stat-item">
      <div class="stat-item-left">
        <img src="assets/img/store-card-people-icon.png" alt="Ícone de pessoas">
      </div>
      <div class="stat-item-right">
        <span>Qtd. Negócios Realizados</span>
        <h4>{{ storeStats.completedDeals || 0 }}</h4>
      </div>
    </div>
  </div>

  <div class="search-bar">

    <select [(ngModel)]="selectedCategory" name="category" type="select" label="Categoria" ngClass="region-select">
      <option [value]="''" disabled>Selecione uma categoria</option>
      <option *ngFor="let option of occupationsListMapped" [value]="option.value">{{ option.label }}</option>
    </select>

    <div class="search-bar-filter">
      <input type="text" [(ngModel)]="searchText" placeholder="O que você procura?" />

      <select [(ngModel)]="selectedRegion" name="region" type="select" label="Região">
        <option [value]="''" disabled>Selecione uma região</option>
        <option *ngFor="let option of citiesListMapped" [value]="option.value">{{ option.label }}</option>
      </select>

      <button class="search-button" (click)="onSearch()">
        <span>Buscar</span>
        <i class="bi bi-search search-icon"></i>
      </button>

      <button class="search-reset-button" (click)="resetFilters()">
        <span>Limpar</span>
        <i class="bi bi-x-circle reset-icon"></i>
      </button>
    </div>
  </div>

  <div class="others">
    <h3>Em destaque</h3>
    <div class="others-grid">
      <div *ngFor="let ad of featuredAdvertisements" class="other-item">
        <div class="other-item-image">
          <img class="logo-image" [src]="ad.banner" alt="{{ ad.companyName }}">
          <!-- Botão de editar no canto superior direito -->
          <div class="action-buttons">
            <button *ngIf="isAdvertisementOwner(ad.createdBy)" class="edit-logo-button"
              (click)="handleEditAdvertisement(ad.id)">
              <i class="bi bi-pencil"></i>
            </button>
            <button *ngIf="isAdvertisementOwner(ad.createdBy)" class="delete-button"
              (click)="deleteAdvertisement(ad.id)">
              <i class="bi bi-trash"></i>
            </button>
            <button *ngIf="!isAdvertisementOwner(ad.createdBy)" class="delete-button"
              (click)="handleSelectScreen(ad.id)">
              <i [class]="isFavorite(ad.id) ? 'bi bi-bookmark-fill' : 'bi bi-bookmark'"></i>
            </button>
          </div>
        </div>
        <h4>{{ ad.companyName }}</h4>
        <p>{{ ad.description }}</p>
        <div class="featured-actions">
          <div class="featured-stats">
            <div class="d-flex justify-content-between featured-stats-row">
              <div class="d-flex align-items-center gap-2">
                <i class="bi bi-bookmark" [class.favorited]="userFavorites.includes(ad)"
                  (click)="toggleFavorite(ad.id)">
                </i>
                <div class="d-flex flex-column featured-stats-column">
                  <span>Favorito:</span>
                  <h3>{{ ad.favorites }}</h3>
                </div>
              </div>

              <i class="bi bi-search search-icon" (click)="openModalSeeAnnouncement(ad.id)"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="others">
    <h3>Outros anunciantes</h3>
    <div class="others-grid">
      <div *ngFor="let ad of otherAdvertisements" class="other-item">
        <div class="other-item-image">
          <img class="logo-image" [src]="ad.banner" alt="{{ ad.companyName }}">
          <div class="action-buttons">
            <button *ngIf="isAdvertisementOwner(ad.createdBy)" class="edit-logo-button"
              (click)="handleEditAdvertisement(ad.id)">
              <i class="bi bi-pencil"></i>
            </button>
            <button *ngIf="isAdvertisementOwner(ad.createdBy)" class="delete-button"
              (click)="deleteAdvertisement(ad.id)">
              <i class="bi bi-trash"></i>
            </button>
            <button *ngIf="!isAdvertisementOwner(ad.createdBy)" class="delete-button"
              (click)="handleSelectScreen(ad.id)">
              <i [class]="isFavorite(ad.id) ? 'bi bi-bookmark-fill' : 'bi bi-bookmark'"></i>
            </button>
          </div>
        </div>
        <h4>{{ ad.companyName }}</h4>
        <p>{{ ad.description }}</p>
        <div class="featured-actions">
          <div class="featured-stats">
            <div class="d-flex justify-content-between featured-stats-row">
              <div class="d-flex align-items-center gap-2">
                <i class="bi bi-bookmark" [class.favorited]="userFavorites.includes(ad)"
                  (click)="toggleFavorite(ad.id)"></i>
                <div class="d-flex flex-column featured-stats-column">
                  <span>Favorito:</span>
                  <h3>{{ ad.favorites }}</h3>
                </div>
              </div>

              <i class="bi bi-search search-icon" (click)="openModalSeeAnnouncement(ad.id)"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-generic-modal *ngIf="showModal" [title]="modalData.title" [message]="modalData.message"
    [confirmText]="modalData.confirmText" [cancelText]="modalData.cancelText" [showCancel]="modalData.showCancel"
    (confirm)="confirmDelete()" (cancel)="showModal = false">
  </app-generic-modal>
</div>