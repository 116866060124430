import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Valida se um valor é um e-mail válido.
 */
export const emailValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const value = control.value || '';
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    return emailRegex.test(value) ? null : { invalidEmail: true };
};

/**
 * Valida se um valor é um telefone válido.
 */
export const phoneValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const value = control.value || '';
    const phoneRegex = /^\+?\d{10,15}$/;

    return phoneRegex.test(value) ? null : { invalidPhone: true };
};

/**
 * Valida se uma data de início não é anterior à data atual.
 */
export const startDateValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) return null;

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const inputDate = new Date(value);
    inputDate.setHours(0, 0, 0, 0);

    return inputDate < today ? { invalidStartDate: true } : null;
};

/**
 * Valida se uma data de término é posterior à data de início.
 */
export const endDateValidator = (startDateControl: AbstractControl): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
        const startDate = startDateControl.value;
        const endDate = control.value;

        if (!startDate || !endDate) return null;

        const start = new Date(startDate);
        const end = new Date(endDate);

        return end < start ? { invalidEndDate: true } : null;
    };
};

/**
 * Valida se um campo é obrigatório.
 */
export const requiredValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const value = control.value || '';
    return value.trim() ? null : { required: true };
};

/**
 * Valida o tamanho mínimo de um texto.
 */
export const minLengthValidator = (minLength: number): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value || '';
        return value.length >= minLength ? null : { minLength: { requiredLength: minLength, actualLength: value.length } };
    };
};

/**
 * Valida o tamanho máximo de um texto.
 */
export const maxLengthValidator = (maxLength: number): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value || '';
        return value.length <= maxLength ? null : { maxLength: { requiredLength: maxLength, actualLength: value.length } };
    };
};

/**
 * Valida se um valor é um número.
 */
export const numberValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    return !isNaN(value) ? null : { invalidNumber: true };
};

export function emailOrPhoneValidator(control: AbstractControl): ValidationErrors | null {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Regex para validar email
    const phonePattern = /^\d{10,11}$/; // Regex para validar telefone (10 ou 11 dígitos)

    const value = control.value;

    // Se o campo estiver vazio, não há erro (isso será tratado pelo `Validators.required`)
    if (!value) {
        return null;
    }

    // Verifica se o valor é um email válido ou um telefone válido
    const isEmailValid = emailPattern.test(value);
    const isPhoneValid = phonePattern.test(value);

    if (isEmailValid || isPhoneValid) {
        return null; // O valor é válido
    }

    // Caso contrário, retorna um erro
    return { invalidContact: true };
}

export function isEmail(contact: string): boolean {
    return contact.includes('@');
}
