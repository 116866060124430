<ng-template #imageSelectionModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title">{{ title }}</h4>
        <button type="button" class="close" aria-label="Fechar" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <label class="image-upload-label" for="image-upload" *ngIf="!cropMode">
            <input id="image-upload" class="image-upload" accept="image/jpeg, image/png, image/jpg" type="file"
                (change)="onFileSelected($event)" />
            <i class="bi bi-card-image"></i>
            <span>Clique aqui para selecionar e enviar a sua foto</span>
            <span *ngIf="!cropMode" class="image-scale-orientations">
                <strong>A imagem deve ter no máximo {{ maxWidth }} x {{ maxHeight }}</strong>
            </span>
        </label>

        <ng-container *ngIf="cropMode">
            <image-cropper [imageChangedEvent]="imageEvent" [maintainAspectRatio]="false" [resizeToWidth]="maxWidth"
                [resizeToHeight]="maxHeight" format="png" (imageCropped)="imageCropped($event)">
            </image-cropper>
        </ng-container>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" *ngIf="cropMode" (click)="removeImage()">
            {{ removeText }}
        </button>
        <button type="button" class="btn btn-primary" (click)="confirmSelection()">
            {{ confirmText }}
        </button>
    </div>
</ng-template>