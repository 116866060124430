import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Connection } from 'src/app/model/connection.model';
import { ModalDataInterface } from '../category-channel.service';
import { EventParticipant } from 'src/app/model/event.model';

@Component({
  selector: 'app-communitie-participants-modal',
  templateUrl: './communitie-participants-modal.component.html',
  styleUrls: ['./communitie-participants-modal.component.css']
})
export class CommunitieParticipantsModalComponent implements OnInit {
  public communityParticipantsFiltered: EventParticipant[] = []
  public allParticipants: EventParticipant[] = []
  public allParticipantsEvent: EventParticipant[] = []
  public selectedParticipants: EventParticipant[] = []

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { data: ModalDataInterface, channelParticipants: Connection[] },
    private dialogRef: MatDialogRef<CommunitieParticipantsModalComponent>
  ) {

  }

  ngOnInit(): void {
    this.allParticipantsEvent = this.allParticipantsEvent = this.data.data.allParticipantsEvent.filter(
      (person: EventParticipant) =>
        !this.data.channelParticipants.some(
          (participant: Connection) => participant.connection.idUser === person.idUser
        )
    );

    this.communityParticipantsFiltered = this.allParticipantsEvent;
  }

  filterCommunityParticipants(event: any) {
    if (event.target.value.length === 0) {
      this.communityParticipantsFiltered = this.allParticipantsEvent;
    } else {
      this.communityParticipantsFiltered = this.allParticipantsEvent.filter((person: EventParticipant) => person.name.toLowerCase().includes(event.target.value.toLowerCase()));
    }
  }

  verifyCommunityParticipants(person: any): boolean {
    return this.selectedParticipants.includes(person);
  }

  addCommunityParticipants(person: any) {
    this.selectedParticipants.push(person);
  }

  rmCommunityParticipants(person: any) {
    this.selectedParticipants.splice(this.selectedParticipants.indexOf(person), 1);
  }

  closeModal(result: boolean, participants?: EventParticipant[]) {
    this.dialogRef.close({ result: result, participants: participants });
  }

  handleSelectEverybody() {
    this.selectedParticipants = this.communityParticipantsFiltered
    if (this.selectedParticipants.length === 0) return
    this.closeModal(true, this.selectedParticipants)
  }

  handleSelect() {
    if (this.selectedParticipants.length === 0) return
    this.closeModal(true, this.selectedParticipants)
  }

}
