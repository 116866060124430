<div class="modal-container">
  <div class="modal-body">
    <section class="event-fields-section">
      <div class="input-container">
        <div class="search-person-container">
          <label for="searchPersonInput" class="input-label">Indique o contato {{nameUserIndication}} para: </label>
          <input id="searchPersonInput" maxlength="50" (input)="filter($event)" placeholder="Procurar pessoas"
            class="form-control" />
        </div>
        <div class="people-container">
          <div class="connection-request-card" *ngFor="let person of connectionsFiltered">


            <img *ngIf="person.uriImageProfile  && person.uriImageProfile !== ''" [src]="person.uriImageProfile" alt="">
            <img *ngIf="!person.uriImageProfile  || !person.uriImageProfile.length" src="/assets/img/imagem_perfil.png"
              alt="">

            <div class="request-info">
              <div class="info">
                <span class="request-name">{{person.name}}</span>
                <span class="request-profession multi-line"
                  [innerHTML]="person.presentation">{{person.presentation}}</span>
              </div>
            </div>

            <i *ngIf="verify(person)" class="btn bi bi-check-square-fill quantity-people-button"
              (click)="remove(person)"></i>
            <i *ngIf="!verify(person)" class="btn bi bi-square quantity-people-button" (click)="add(person)"></i>
          </div>
        </div>
      </div>
    </section>
    <div *ngIf="connectionsFiltered.length === 0">
      <span class="no-items">Nenhum participante encontrado</span>
    </div>
  </div>
  <div class="modal-footer">
    <button class="item-delete-button" (click)="closeModal(false)">
      Cancelar
    </button>
    <button class="item-cancel-button" (click)="handleSelectEverybody()">
      Adicionar Todos
    </button>
    <button class="item-cancel-button" (click)="handleSelect()">
      Adicionar Selecionado
    </button>
  </div>
</div>