export const TOGGLE_CONFIG = {
    value: true,
    name: '',
    disabled: false,
    height: 35,
    width: 90,
    margin: 0,
    fontSize: 15,
    speed: 300,
    color: {
        checked: '#F6F6F6',
        unchecked: '#F6F6F6',
    },
    switchColor: {
        checked: 'green',
        unchecked: '#7B7B7B',
    },
    labels: {
        unchecked: 'Não',
        checked: 'Sim',
    },
    fontColor: {
        checked: 'green',
        unchecked: '#7B7B7B',
    },
    textAlign: 'center',
};

export const LOGO_CONFIG = {
    showImage: true,
    showTooltip: false,
};

export const IMAGE_CONFIG = {
    isSelectionImageModalOpen: false,
    cropMode: false,
    imageUpdated: false,
};
