import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { addDays, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import {
  ApexChart,
  ChartComponent,
} from 'ng-apexcharts';
import { EventsService } from '../events/events.service';
import { DashboardCard, IDashboardUser } from '../model/dashboard.model';
import { IUserProfilePlan } from '../model/user-plan.model';
import { IUserProfileWithCSS } from '../model/user-profile-css.model';
import {
  UserProfileService
} from '../user-profile/user-profile.service';
import { LoadingService } from '../new-sluper/core/service/loading.service';
import { ChartOptions, SeriesData } from '../model/charts.model';
import { IFormattedDashboardData } from '../model/dashboard-chart.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnChanges {
  @ViewChild('chart') chart?: ChartComponent;
  public chartOptions: ChartOptions = {
    series: [],
    chart: {
      height: 350,
      type: 'area',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      categories: [],
    },
    tooltip: {},
  };
  @Input() userProfile!: IUserProfileWithCSS | null;
  @Input() userProfilePlan!: IUserProfilePlan;
  @Output() linkRequired = new EventEmitter<boolean>();

  dashboardData: IFormattedDashboardData | null = null;
  public dashboardUser: IDashboardUser | null = null;

  screenWidth: any;
  screenHeight: any;

  profileAccessSeries: SeriesData[] = [{ name: 'Acessos', data: [] }];
  profileAccessChart: ApexChart = { type: 'bar', height: 350, redrawOnParentResize: true };
  profileAccessXAxis: { categories: string[] } = { categories: [] };
  profileAccessColors = ['#FFA500'];
  
  
  dashboardCards: DashboardCard[] = [
    { title: 'Visitas ao perfil', icon: 'bi-person-check', color: '#81bd86', key: 'profileAccessComplete' },
    { title: 'Links acessados', icon: 'bi-hdd-rack', color: '#81bd86', key: 'accessedLinksComplete' },
    { title: 'Redes acessadas', icon: 'bi-bullseye', color: '#81bd86', key: 'socialNetworkAccessedComplete' },
    { title: 'Conexões', icon: 'bi-people', color: '#81bd86', key: 'connectionsComplete' },
    { title: 'Indicações Realizadas', icon: 'bi-person-exclamation', color: '#81bd86', key: 'indicationsComplete' },
    { title: 'Eventos Participados', icon: 'bi-calendar-check', color: '#81bd86', key: 'eventsParticipatedComplete' },
    { title: 'Eventos Organizados', icon: 'bi-calendar-range', color: '#81bd86', key: 'eventsOrganizedComplete' },
    { title: 'Comunidades Participando', icon: 'bi-send-check', color: '#81bd86', key: 'communityParticipatingComplete' },
    { title: 'Comunidades Organizadas', icon: 'bi-send-exclamation', color: '#81bd86', key: 'communityOrganizedComplete' }
  ];

  constructor(
    private userProfileService: UserProfileService,
    private eventsService: EventsService,
    private cdr: ChangeDetectorRef,
    private loadingService:LoadingService
  ) {
    this.getScreenSize();
    this.initializeChartOptions();
    this.buscaDashboardEvents();
  }

  ngAfterViewInit() {
    this.loadChartData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['userProfilePlan']) {
      this.loadChartData();
    }
  }

  initializeChartOptions() {
    const lastForWeeksPeriods: string[] = [];
    const currentDate = new Date();

    for (let i = 0; i < 4; i++) {
      const currentWeekStartDate = addDays(currentDate, -7 * (i + 1));
      const currentWeekEndDate = addDays(currentWeekStartDate, i === 0 ? 7 : 6);

      lastForWeeksPeriods.push(
        `${format(currentWeekStartDate, "dd'/'MM'/'yyyy", {
          locale: ptBR,
        })} a ${format(currentWeekEndDate, "dd'/'MM'/'yyyy", {
          locale: ptBR,
        })}`,
      );
    }

    this.chartOptions.xaxis.categories = lastForWeeksPeriods.reverse();
  }
  
  updateProfileAccessChart() {
    if (this.dashboardUser?.profileAccessCommunityChart) {
        const { categories, series } = this.dashboardUser.profileAccessCommunityChart;
        
        this.profileAccessSeries = Object.keys(series).map(seriesName => ({
            name: seriesName,
            data: series[seriesName].map(Number),
        }));

        this.profileAccessXAxis = { categories };
    }
  }

  loadChartData() {
    if (this.userProfilePlan && this.userProfilePlan.profilePlanLimits.dashViews) {
      const lastForWeeksPeriods: string[] = [];
      const currentDate = new Date();

      for (let i = 0; i < 4; i++) {
        const currentWeekStartDate = addDays(currentDate, -7 * (i + 1));
        const currentWeekEndDate = addDays(currentWeekStartDate, i === 0 ? 7 : 6);

        lastForWeeksPeriods.push(
          `${format(currentWeekStartDate, "dd'/'MM'/'yyyy", {
            locale: ptBR,
          })} a ${format(currentWeekEndDate, "dd'/'MM'/'yyyy", {
            locale: ptBR,
          })}`
        );
      }

      this.userProfileService.getDashboardData().subscribe(({ body }) => {
        if (body) {
          this.dashboardData = {
            acessedLinksCount: body.acessedLinksCount,
            profileAccessByWeeks: body.profileAccessByWeeks,
            linksClickedByWeeks: body.linksClickedByWeeks,
            socialNetworkByWeeks: body.socialNetworkByWeeks,
            profileAccessCount: body.profileAccessCount,
            socialNetworkAcessedCount: body.socialNetworkAcessedCount,
            topAccessedLinks: body.topAccessedLinks,
            topAccessedSocialNetworks: body.topAccessedSocialNetworks,
            updateDate: body.updateDate,
          };

          this.chartOptions.series = [
            {
              name: 'Perfil',
              data: this.dashboardData.profileAccessByWeeks.reverse(),
            },
            {
              name: 'Redes sociais',
              data: this.dashboardData.socialNetworkByWeeks.reverse(),
            },
            {
              name: 'Links',
              data: this.dashboardData.linksClickedByWeeks.reverse(),
            },
          ];

          this.chartOptions.xaxis.categories = lastForWeeksPeriods.reverse();
          this.cdr.detectChanges();
        }
      });
    }
  }

  getDashboardInfo({ page, size }: { page: number; size: number }): void {
    this.userProfileService.getDashboardData().subscribe(({ body }) => {
      if (body) {
        this.dashboardData = {
          acessedLinksCount: body.acessedLinksCount,
          profileAccessCount: body.profileAccessCount,
          profileAccessByWeeks: body.profileAccessByWeeks,
          linksClickedByWeeks: body.linksClickedByWeeks,
          topAccessedLinks: body.topAccessedLinks,
          topAccessedSocialNetworks: body.topAccessedSocialNetworks,
          socialNetworkByWeeks: body.socialNetworkByWeeks,
          socialNetworkAcessedCount: body.socialNetworkAcessedCount,
          updateDate: body.updateDate,
        };
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  buscaDashboardEvents() {
    this.loadingService.show();
    this.eventsService.getEventDashboadUser().subscribe(({ body }) => {
      if (body) {
        this.dashboardUser = body;
        this.updateDashboardCards(); 
        this.updateProfileAccessChart();
        this.cdr.detectChanges(); 
      }
      this.loadingService.hide();

    });
  }
  
  updateDashboardCards() {
    if (!this.dashboardUser) {
      console.warn('dashboardUser está indefinido ou nulo.');
      return;
    }
  
    this.dashboardCards = this.dashboardCards.map(card => ({
      ...card,
      percentage: this.dashboardUser?.[card.key]?.percentage ?? 0,
      difference: this.dashboardUser?.[card.key]?.difference ?? 0,
      count: this.dashboardUser?.[card.key]?.count ?? this.dashboardUser?.[card.key] ?? 0
    }));
  }
  
    

  transformEventsListToLinks(): string[] {
    if (this.dashboardUser?.listEventsOrganized) {
      return this.dashboardUser.listEventsOrganized.map(event => event.name);
    }
    return [];
  }

  transformCommunityListToLinks(): string[] {
    if (this.dashboardUser?.listCommunityOrganized) {
      return this.dashboardUser.listCommunityOrganized.map(event => event.name);
    }
    return [];
  }
}
