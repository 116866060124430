<div id="advertisement-card">
  <!-- Imagem do Anúncio -->
  <div id="advertisement-image">
    <img [src]="advertisement?.banner" [alt]="advertisement?.companyName">

    <div class="featured">
      <h4>{{ advertisement?.companyName }}</h4>
      <div class="featured-stats">
        <div class="d-flex featured-stats-row">
          <i class="bi bi-star"></i>
          <div class="d-flex flex-column featured-stats-column">
            <span>Favoritos:</span>
            <h3>{{ advertisement?.favorites }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Descrição -->
  <p class="personalized-scroll">
    {{ advertisement?.description }}
  </p>

  <div class="contact-items">
    <span>Contato</span>
    <div class="contact-item">
      <a [href]="contactLink" target="_blank" rel="noopener noreferrer">
        <i [class]="contactIcon"></i> {{ advertisement?.contact }}
      </a>
    </div>
  </div>

  <!-- Redes Sociais -->
  <div *ngIf="listSocialNetwork && listSocialNetwork.length > 0" class="contact-items">
    <span>Redes sociais</span>
    <div class="contact-item">
      <a *ngFor="let social of listSocialNetwork" [href]="social.link" target="_blank" rel="noopener noreferrer">
        <i [class]="'bi bi-' + social.type"></i>
      </a>
    </div>
  </div>
</div>