<div class="body-div">
  <header class="title-container">
    <h1 class="title" translate>dashboard.title</h1>
  </header>

  <div class="content-container">
    <section class="dashboard-items-container"
      [style]="!userProfilePlan.profilePlanLimits.dashViews ? 'filter: blur(6px); -webkit-filter: blur(8px); z-index: 1;' : ''">
      <div class="dashboard-content">
        <ng-container *ngFor="let card of dashboardCards">
          <app-dashboard-card-new
            class="card-container"
            [dashboardCardData]="{
              title: card.title,
              icon: card.icon,
              color: card.color,
              value: card.count,
              percentage: card.percentage,
              difference: card.difference
            }">
          </app-dashboard-card-new>
        </ng-container>
      </div>

      <div class="graphs-container">
        <div class="access-graphs-box">
          <span class="access-graph-title">Métricas de acesso por semana</span>

          <a target="_blank" href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal"
            class="no-dash-graph-access-button"
            *ngIf="userProfilePlan.profilePlanLimits.dashAccessGraph !== undefined && !userProfilePlan.profilePlanLimits.dashAccessGraph">
            <i class="bi bi-lock-fill"></i>

            <div class="no-dash-graph-messages">
              Para visualizar o gráfico de acesso,
              <a target="_blank"
                href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal">faça upgrade do
                seu plano</a>
            </div>
          </a>

          <div id="chart" class="chart-container" [ngClass]="!userProfilePlan.profilePlanLimits.dashAccessGraph ? 'hidden' : ''">
            <apx-chart style="width: 100%" [series]="chartOptions.series" [chart]="chartOptions.chart"
              [xaxis]="chartOptions.xaxis" [stroke]="chartOptions.stroke" [tooltip]="chartOptions.tooltip"
              [dataLabels]="chartOptions.dataLabels"></apx-chart>
          </div>
        </div>
      </div>
      <div class="graphs-container">
        <div class="access-graphs-box">
          <span class="access-graph-title">Acessos ao Perfil por Comunidade</span>

          <a target="_blank" href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal"
            class="no-dash-graph-access-button"
            *ngIf="userProfilePlan.profilePlanLimits.dashAccessGraph !== undefined && !userProfilePlan.profilePlanLimits.dashAccessGraph">
            <i class="bi bi-lock-fill"></i>

            <div class="no-dash-graph-messages">
              Para visualizar o gráfico de acesso,
              <a target="_blank"
                href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal">faça upgrade do
                seu plano</a>
            </div>
          </a>

          <div id="chart" class="chart-container" [ngClass]="!userProfilePlan.profilePlanLimits.dashAccessGraph ? 'hidden' : ''">
            <apx-chart 
            [series]="profileAccessSeries" 
            [chart]="profileAccessChart"
            [xaxis]="profileAccessXAxis"
            [colors]="profileAccessColors">
            </apx-chart>
          </div>
        </div>
      </div>

      <div *ngIf="dashboardData?.updateDate" class="last-update-container">
        <span class="last-update-label">última atualização: {{ dashboardData?.updateDate | date: 'dd/MM/yyyy - HH:mm'
          }}</span>
      </div>

      <div class="fictitious-data-container" *ngIf="!userProfilePlan.profilePlanLimits.dashViews">
        <span class="fictitious-data-label">DADOS FICTÍCIOS</span>
      </div>
    </section>

    <div class="no-access-container" *ngIf="!userProfilePlan.profilePlanLimits.dashViews">
      <div class="no-access-content">
        <span class="no-access-text" translate>Seu plano atual não permite acesso ao dashboard.</span>
        <img src="/assets/img/undraw_access_denied_re_awnf.svg" style="width: 200px" alt="No access"
          class="no-access-img" />

        <span class="cta-label">Para acessar,
          <a target="_blank" href="https://sluper.digital/produto/sluper-professional/?attribute_pa_plano=personal">faça
            upgrade do seu plano</a>.</span>
      </div>
    </div>
  </div>
</div>
