import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.css']
})
export class FormFieldComponent {
  @Input() control!: FormControl; // Controle do formulário
  @Input() type: string = 'text'; // Tipo do campo (text, textarea, select, multiselect, etc.)
  @Input() label?: string; // Rótulo do campo
  @Input() placeholder?: string; // Placeholder do campo
  @Input() errorMessages?: { [key: string]: string }; // Mensagens de erro
  @Input() options: { value: string; label: string }[] = []; // Opções para campos do tipo select e multiselect

  // Retorna a mensagem de erro apropriada
  get errorMessage(): string | null {
    if (!this.control || !this.control.errors || !this.control.touched) {
      return null;
    }

    const errors = this.control.errors;

    if (this.errorMessages) {
      for (const errorKey in errors) {
        if (this.errorMessages[errorKey]) {
          return this.errorMessages[errorKey];
        }
      }
    }

    if (errors['required']) {
      return 'Este campo é obrigatório.';
    }
    if (errors['minlength']) {
      return `O campo deve ter no mínimo ${errors['minlength'].requiredLength} caracteres.`;
    }
    if (errors['maxlength']) {
      return `O campo deve ter no máximo ${errors['maxlength'].requiredLength} caracteres.`;
    }
    if (errors['pattern']) {
      return 'O formato do campo é inválido.';
    }

    return 'Campo inválido.';
  }

  // Adiciona ou remove valores selecionados para um select múltiplo
  onMultiSelectChange(event: Event) {
    const selectedOptions = (event.target as HTMLSelectElement).selectedOptions;
    const values = Array.from(selectedOptions).map(option => option.value);
    this.control.setValue(values);
  }

  // Verifica se um valor já foi selecionado (útil para manter a seleção)
  isSelected(value: string): boolean {
    return this.control.value?.includes(value);
  }
}
