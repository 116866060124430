<!-- <div class="alert-div">
  <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
    {{ alertMessage }}
  </ngb-alert>
</div> -->
<app-loading-spinner [bgTransparent]="true" *ngIf=" loading"></app-loading-spinner>



<div class="modal-container">
  <div class="modal-header">
    <h4 class="modal-title">{{ modalType === 'create' ? 'Criar Categoria' : 'Editar Categoria' }}</h4>
  </div>
  <div class="modal-body">
    <label for="categoryName" class="input-label">Nome da categoria</label>
    <div class="input-group">
      <input id="categoryName" placeholder="Nome da categoria" type="text" class="form-control"
        [formControl]="categoryName" />
      <span *ngIf="categoryName.invalid && categoryName.touched " class="text-danger">
        O nome da categoria é obrigatório.
      </span>
    </div>

    <div id="my-channels">
      <h4>Canais</h4>
      <div *ngFor="let channel of channels">
        <span>{{ channel?.icon }}</span>
        <span>{{ channel?.name }}</span>
        <i class=""></i>
      </div>
    </div>

    <div class="category-icon-container">
      <i class="btn bi bi-plus-square-fill category-icon"></i>
      <span (click)="openCreateChannelModal()">Adicionar Canais</span>
    </div>

    <div id="my-channels">
      <div *ngFor="let channel of newChannels">
        <span>{{ channel?.icon }}</span>
        <span>{{ channel?.name }}</span>
        <i (click)="rmChannel(channel.id)" class="bi bi-dash-square-fill"></i>
      </div>
    </div>

    <div class="d-flex justify-content-between align-items-center">
      <div class="channel-participants-container">
        <span>Perfis adicionados</span>
      </div>
      <div class="channel-icon-container" (click)="openModalAddCategoryMembers()" style="margin-right: 1.9rem;">
        <i class="btn bi bi-plus-square-fill channel-icon"></i>
        <span>Adicionar Perfil</span>
      </div>
    </div>

    <div class="communities-participants" *ngIf="categoryMembers.length">
      <section class="event-fields-section">
        <div class="people-container">
          <div class="connection-request-card"
            *ngFor="let item of categoryMembers | slice: 0 : visibleCategoryMembersCount">
            <div class="request-info">
              <div class="info">
                <span class="request-name">{{ item.name }}</span>
              </div>
            </div>
            <i class="btn bi bi-dash-square-fill quantity-people-button" (click)="rmCategoryMembers(item)"></i>
          </div>
        </div>
      </section>
      <div *ngIf="categoryMembers.length > 2">
        <button class="btn show-more-button" (click)="toggleShowMoreCategoryMember($event)">
          {{ visibleCategoryMembersCount === 2 ? 'Ver Mais' : 'Ver Menos' }}
        </button>
      </div>
    </div>

    <div class="d-flex justify-content-between align-items-center">
      <div class="channel-participants-container">
        <span>Membros adicionados</span>
      </div>
      <div class="channel-icon-container" (click)="openModalAddMembers()">
        <i class="btn bi bi-plus-square-fill channel-icon"></i>
        <span>Adicionar membros</span>
      </div>
    </div>

    <div class="communities-participants" *ngIf="channelParticipants.length">
      <section class="event-fields-section">
        <div class="people-container">
          <div class="connection-request-card"
            *ngFor="let person of channelParticipants | slice: 0 : visibleParticipantsCount">
            <img *ngIf="person.connection.uriImageProfile && person.connection.uriImageProfile !== ''"
              [src]="person.connection.uriImageProfile" alt="" />
            <img *ngIf="!person.connection.uriImageProfile || !person.connection.uriImageProfile.length"
              src="/assets/img/imagem_perfil.png" alt="" />

            <div class="request-info">
              <div class="info">
                <span class="request-name">{{ person.connection.name }}</span>
                <span class="request-profession multi-line" [innerHTML]="person.connection.presentation">{{
                  person.connection.presentation
                  }}</span>
              </div>
            </div>
            <i class="btn bi bi-dash-square-fill quantity-people-button" (click)="rmCommunityParticipants(person)"></i>
          </div>
        </div>
      </section>
      <div *ngIf="channelParticipants.length > 2">
        <button class="btn show-more-button" (click)="toggleShowMore($event)">
          {{ visibleParticipantsCount === 2 ? 'Ver Mais' : 'Ver Menos' }}
        </button>
      </div>
    </div>
    <div *ngIf="channelParticipants.length === 0">
      <span class="no-items">Nenhum participante encontrado</span>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="item-delete-button" (click)="closeModal(false, '')">Cancelar</button>
    <button (click)="saveCategory()" class="item-cancel-button">
      {{ modalType === 'create' ? 'Criar Categoria' : 'Salvar alterações' }}
    </button>
  </div>
</div>