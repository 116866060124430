import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { SluperEvent } from 'src/app/model/event.model';
import { IDocumentPreferences } from 'src/app/model/preferences.model';
import { ToastService } from 'src/app/new-sluper/core/service/toast.service';
import { ICidade, UserProfileService } from 'src/app/user-profile/user-profile.service';
import { Advertisement, AdvertisementService, Favorite } from './manager-company-advertisement/advertisement.service';
import { SeeAnnouncementComponent } from './see-announcement/see-announcement.component';

@Component({
  selector: 'app-community-store',
  templateUrl: './community-store.component.html',
  styleUrls: ['./community-store.component.css'],
})
export class CommunityStoreComponent implements OnInit {
  advertisements: Advertisement[] = [];
  filteredAdvertisements: Advertisement[] = [];

  featuredAdvertisements: Advertisement[] = [];
  otherAdvertisements: Advertisement[] = [];
  userFavorites: Favorite[] = [];
  storeStats: any = {};
  selectedAdvertisementId: number | null = null;

  occupationsList!: IDocumentPreferences[];
  occupationsListMapped: { value: string; label: string }[] = [];

  citiesList!: ICidade[];
  citiesListMapped: { value: string; label: string }[] = [];

  selectedCategory: string | number = '';
  selectedRegion: string | number = '';

  searchText!: string;

  userId: string = '';
  communityId: string = '';

  public showModal: boolean = false;
  public modalData: {
    title: string;
    message: string;
    confirmText: string;
    cancelText: string;
    showCancel: boolean;
  } = {
      title: '',
      message: '',
      confirmText: '',
      cancelText: '',
      showCancel: true
    };

  @Input() isUserCommunityOwner: boolean = false;
  @Input() communityItem!: SluperEvent;

  public loading: boolean = false;
  public screens:
    | 'store-screen'
    | 'contact-screen'
    | 'company-advertisement-screen' = 'store-screen';

  constructor(
    private readonly dialog: MatDialog,
    private toastrService: ToastService,
    private advertisementService: AdvertisementService,
    private userProfileService: UserProfileService
  ) { }

  ngOnInit() {
    const sessionProfile = this.userProfileService.getUserSessionProfile();
    this.userId = sessionProfile?.userId as string;
    this.communityId = sessionProfile?.communityId as string;

    this.getPreferences();
    this.getCidadesDeMinasGerais();

    this.refreshListings();
  }


  refreshListings(): void {
    this.loadAdvertisements();
    this.loadFeatured();
    this.loadOthers();
    this.loadUserFavorites();
    this.loadStoreStats();
  }

  loadAdvertisements(): void {
    this.executeWithLoading(
      this.advertisementService.getAllAdvertisements(this.communityId),
      'Erro ao carregar anúncios. Tente novamente.',
      (data) => {
        this.advertisements = data;
        this.filteredAdvertisements = [...data];
      }
    );
  }

  toggleFavorite(advertisementId?: number): void {
    if (advertisementId !== undefined) {
      this.executeWithLoading(
        this.advertisementService.toggleFavorite(advertisementId, this.userId, this.communityId),
        'Erro ao favoritar anúncio. Tente novamente.'
      );
    }
  }

  loadFeatured(): void {
    this.executeWithLoading(
      this.advertisementService.getFeaturedAdvertisements(this.communityId),
      'Erro ao carregar anúncios em destaque. Tente novamente.',
      (data) => this.featuredAdvertisements = data
    );
  }

  loadOthers(): void {
    this.executeWithLoading(
      this.advertisementService.getOtherAdvertisements(this.communityId),
      'Erro ao carregar outros anúncios. Tente novamente.',
      (data) => this.otherAdvertisements = data
    );
  }

  loadUserFavorites(): void {
    this.executeWithLoading(
      this.advertisementService.getUserFavorites(this.userId, this.communityId),
      'Erro ao carregar favoritos. Tente novamente.',
      (data) => this.userFavorites = data
    );
  }

  loadStoreStats(): void {
    this.executeWithLoading(
      this.advertisementService.getStoreStats(this.communityId),
      'Erro ao carregar estatísticas. Tente novamente.',
      (data) => this.storeStats = data
    );
  }

  isFavorite(advertisementId: any) {
    return this.userFavorites.find(prop => prop.id == advertisementId)
  }

  isAdvertisementOwner(createdBy: string | undefined): boolean {
    if (!createdBy || !this.userId) {
      return false;
    }
    return createdBy === this.userId;
  }

  executeWithLoading<T>(
    request: Observable<T>,
    errorMessage: string,
    successCallback?: (data: T) => void,
  ): void {
    this.loading = true;
    request.subscribe({
      next: (data) => {
        if (successCallback) successCallback(data);
      },
      error: (error) => {
        console.error(errorMessage, error);
        this.toastrService.show(errorMessage, 'error');
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  handleOpenCompanyAdvertisement() {
    this.selectedAdvertisementId = null;
    this.screens = 'company-advertisement-screen';
  }

  handleCloseScreens() {
    this.screens = 'store-screen';
  }

  handleSubmittedCompanyAdvertisement() {
    this.refreshListings()
    this.screens = 'store-screen';
  }

  openModalSeeAnnouncement(advertisementId: any): void {
    this.loading = true;
    this.advertisementService.getAdvertisementById(advertisementId).subscribe({
      next: (data) => {
        this.dialog.open(SeeAnnouncementComponent, {
          data: {
            advertisement: data
          },
          width: '600px',
        });
      },
      error: (error) => {
        console.error('Erro ao carregar o anúncio:', error);
        this.toastrService.show('Erro ao carregar o anúncio. Tente novamente.', 'error');
      },
    });
    this.loading = false;
  }


  openModalContactAnnouncement(advertisementId: any): void {
    this.selectedAdvertisementId = advertisementId;
    this.screens = 'contact-screen';
  }

  handleEditAdvertisement(advertisementId: any): void {
    this.selectedAdvertisementId = advertisementId;
    this.screens = 'company-advertisement-screen';
  }

  deleteAdvertisement(advertisementId: any): void {
    if (!advertisementId) {
      console.error('Nenhum ID de anúncio encontrado.');
      return;
    }

    // Configura os dados do modal antes de exibi-lo
    this.modalData = {
      title: 'Excluir Anúncio',
      message: 'Tem certeza de que deseja excluir este anúncio? Esta ação não pode ser desfeita.',
      confirmText: 'Excluir',
      cancelText: 'Cancelar',
      showCancel: true
    };

    this.selectedAdvertisementId = advertisementId; // Armazena o ID do anúncio
    this.showModal = true; // Exibe o modal
  }

  confirmDelete(): void {
    if (!this.selectedAdvertisementId) {
      console.error('Nenhum ID de anúncio encontrado para exclusão.');
      return;
    }

    this.executeWithLoading(
      this.advertisementService.deleteAdvertisement(this.selectedAdvertisementId, this.communityId),
      'Erro ao excluir anúncio. Tente novamente.',
      () => {
        this.showModal = false;
        this.toastrService.show('Anúncio excluído com sucesso!', 'success');
        this.refreshListings();
      }
    );
  }

  handleSelectScreen(advertisementId: any): void {
    this.executeWithLoading(
      this.advertisementService.toggleFavorite(advertisementId, this.userId, this.communityId),
      'Erro ao favoritar o anúncio. Tente novamente.',
      () => {
        this.showModal = false; // Esconde o modal
        this.toastrService.show('Anúncio favoritado com sucesso!', 'success');
        this.refreshListings();
      }
    );
  }

  getPreferences() {
    this.userProfileService
      .getPreferencesRemoveResponse()
      .subscribe({
        next: (response) => {
          if (response !== null) {
            this.occupationsList = response.filter(o => o.type === 'AREA_ATUACAO');
            this.occupationsList.sort((a, b) => a.description.localeCompare(b.description));
          }

          this.occupationsListMapped = this.occupationsList?.map(occupation => ({
            value: occupation.id.toString(),
            label: occupation.description
          }));
        },
        error: (res) => console.error(res),
      });
  }

  getCidadesDeMinasGerais() {
    this.userProfileService
      .getCidadesDeMinasGerais()
      .subscribe({
        next: (response) => {
          if (response !== null) {
            this.citiesList = response.sort((a, b) => a.nome.localeCompare(b.nome));
          }

          this.citiesListMapped = this.citiesList?.map(occupation => ({
            value: occupation.id.toString(),
            label: occupation.nome
          }));
        },
        error: (res) => console.error(res),
      });
  }

  onSearch(): void {
    const filtered = this.advertisements.filter(ad => {
      const matchesCategory = this.selectedCategory
        ? ad.category === this.selectedCategory
        : true;

      const matchesRegion = this.selectedRegion
        ? ad.region === this.selectedRegion
        : true;

      const matchesSearch = this.searchText
        ? (
          ad.description?.toLowerCase().includes(this.searchText.toLowerCase()) ||
          ad.companyName?.toLowerCase().includes(this.searchText.toLowerCase())
        )
        : true;

      return matchesCategory && matchesRegion && matchesSearch;
    });

    this.featuredAdvertisements = filtered.filter(ad => ad.premium);
    this.otherAdvertisements = filtered.filter(ad => !ad.premium);

    this.updateFilteredStats();
  }

  resetFilters(): void {
    this.selectedCategory = '';
    this.selectedRegion = '';
    this.searchText = '';

    this.featuredAdvertisements = this.advertisements.filter(ad => ad.premium);
    this.otherAdvertisements = this.advertisements.filter(ad => !ad.premium);

    this.updateFilteredStats();
  }


  updateFilteredStats(): void {
    this.storeStats.totalAdvertisements = this.filteredAdvertisements.length;
    this.storeStats.premiumAdvertisements = this.filteredAdvertisements.filter(ad => ad.premium).length;
    this.storeStats.completedDeals = this.filteredAdvertisements.reduce((sum, ad) => sum + (ad.favorites || 0), 0);
  }
}
