<app-loading-spinner [customBg]="'transparent'" *ngIf="loading"></app-loading-spinner>

<div class="categories-container">
  <div class="categories-header">
    <h3>Categorias</h3>
    <button class="create-category-btn" (click)="openCreateCategoryModal()">
      <i class="bi bi-plus"> </i>
      Criar Categoria
    </button>
  </div>

  <div class="table-wrapper">
    <table class="categories-table" *ngIf="communityCategories.length">
      <thead>
        <tr>
          <th>Nome da Categoria</th>
          <th>Qtd. Canais</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let category of communityCategories">
          <td>
            {{ category.name }}
          </td>
          <td>
            {{ category.channelCount }}
          </td>
          <td>
            <div class="d-flex align-items-center">
              <div class="categories-delete-option mx-1" (click)="openDeleteCategoryModal(category)"></div>
              <div class="categories-edit-option mx-1" (click)="openEditCategoryModal(category)"></div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <h3 class="no-items" *ngIf="!communityCategories.length">Nenhuma categoria cadastrada</h3>
  </div>
</div>

<div class="channels-container">
  <div class="channels-header">
    <h3>Canais</h3>
    <button class="create-channel-btn" (click)="openCreateChannelModal()">
      <i class="bi bi-plus"></i>
      Criar Canal
    </button>
  </div>

  <div class="tabble-wrapper">
    <table class="channels-table" *ngIf="communityCategories.length">
      <thead>
        <tr>
          <th>Nome do Canal</th>
          <th>Categoria</th>
          <!-- <th>Qtd. Usuários</th> -->
          <th></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let category of communityCategories">
          <tr *ngFor="let channel of category.channels">
            <td>
              {{ channel.name }}
            </td>
            <td>
              {{ category.name }}
            </td>
            <!-- <td>
              {{ channel.users?.length ? channel.users.length : '0' }}
            </td> -->
            <td>
              <div class="d-flex align-items-center">
                <div class="channels-delete-option mx-1" (click)="openDeleteChannelModal(channel)"></div>
                <div class="channels-edit-option mx-1" (click)="openEditChannelModal(channel)"></div>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <h3 class="no-items" *ngIf="!hasChannel">
      Nenhum canal cadastrado
    </h3>
  </div>
</div>