import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-image-upload-modal',
  templateUrl: './image-upload-modal.component.html',
  styleUrls: ['./image-upload-modal.component.css']
})
export class ImageUploadModalComponent {
  @Input() title: string = 'Selecionar Imagem';
  @Input() confirmText: string = 'Confirmar';
  @Input() removeText: string = 'Remover';
  @Input() cropMode: boolean = false;
  @Input() maxWidth: number = 1080;
  @Input() maxHeight: number = 1080;
  @Input() imageEvent: any;

  @Output() imageSelected = new EventEmitter<any>();
  @Output() imageCroppedEvent = new EventEmitter<any>();
  @Output() imageRemoved = new EventEmitter<void>();
  @Output() modalClosed = new EventEmitter<void>();

  @ViewChild('imageSelectionModal') modalContent!: any;

  private modalRef!: NgbModalRef;

  constructor(private modalService: NgbModal) { }

  openModal(): void {
    this.modalRef = this.modalService.open(this.modalContent, {
      size: 'lg',
      centered: true
    });

    this.modalRef.result.then(
      () => this.modalClosed.emit(),
      () => this.modalClosed.emit()
    );
  }

  closeModal(): void {
    this.modalRef.close();
  }

  onFileSelected(event: any): void {
    this.imageSelected.emit(event);
  }

  imageCropped(event: any): void {
    this.imageCroppedEvent.emit(event);
  }

  confirmSelection(): void {
    this.modalRef.close();
  }

  removeImage(): void {
    this.imageRemoved.emit();
  }
}
