import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocumentUserProfile } from 'src/app/model/document-user-profile.model';
import { ISocialNetwork } from 'src/app/model/social-network.model';
import { isEmail } from 'src/app/utils/validators';

@Component({
  selector: 'app-see-announcement',
  templateUrl: './see-announcement.component.html',
  styleUrls: ['./see-announcement.component.css'],
})
export class SeeAnnouncementComponent {
  advertisement: any = '';
  contactLink: string = '';
  contactIcon: string = '';
  userProfileDetails: DocumentUserProfile | null = null;
  listSocialNetwork: ISocialNetwork[] | undefined = [];

  constructor(@Inject(MAT_DIALOG_DATA)
  public data: { advertisement: any },
    private matDialogRef: MatDialogRef<SeeAnnouncementComponent>
  ) {
    this.advertisement = this.data.advertisement;
    this.listSocialNetwork = this.advertisement?.listSocialNetwork;
    this.setContactInfo();
  }

  private setContactInfo() {
    const contact = this.advertisement?.contact;
    if (isEmail(contact)) {
      this.contactLink = 'mailto:' + contact;
      this.contactIcon = 'bi bi-envelope';
    } else {
      this.contactLink = 'https://wa.me/+550' + contact;
      this.contactIcon = 'bi bi-whatsapp';
    }
  }

  closeModal() {
    this.matDialogRef.close();
  }
}
