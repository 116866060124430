import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';
import {
  CategoryChannelService,
  CategoryInterface,
  ChannelInterface,
  ModalDataInterface,
} from 'src/app/categoryAndChannel/category-channel.service';
import { CreateCategoryModalComponent } from 'src/app/categoryAndChannel/create-category-modal/create-category-modal.component';
import { CreateChannelModalComponent } from 'src/app/categoryAndChannel/create-channel-modal/create-channel-modal.component';
import { DeleteSideMenuOptionComponent } from 'src/app/categoryAndChannel/delete-side-menu-option/delete-side-menu-option.component';
import { Connection } from 'src/app/model/connection.model';
import { DocumentUserProfile } from 'src/app/model/document-user-profile.model';
import { EventParticipant, SluperEvent } from 'src/app/model/event.model';
import { IUserProfilePlan } from 'src/app/model/user-plan.model';
import { ToastService } from 'src/app/new-sluper/core/service/toast.service';
import { IChannels } from '../channels-categories-service/channel-categories.service';

@Component({
  selector: 'app-channels-categories-settings',
  templateUrl: './channels-categories-settings.component.html',
  styleUrls: ['./channels-categories-settings.component.css'],
})
export class ChannelsCategoriesSettingsComponent implements OnInit {
  @Input() communityItem!: SluperEvent;
  @Input() userProfile: DocumentUserProfile | undefined;
  @Input() userProfilePlan!: IUserProfilePlan;
  @Input() userConnections!: Connection[];
  @Input() allParticipantsEvent!: EventParticipant[];
  @Output() atualizarSideMenu = new EventEmitter<boolean>();

  public loading: boolean = false;
  public channels: IChannels[] = [];
  public activeMembers: EventParticipant[] = [];
  public allActiveMembers: EventParticipant[] = [];
  public communityCategories: CategoryInterface[] = [];
  public channelsWithoutCategory: ChannelInterface[] = [];

  categoryModalData: {
    name: string;
  } = {
    name: '',
  };

  channelModalData: {
    name: string;
    selectedEmoji: string | null;
    isEmojiPickerVisible: boolean;
    communityParticipantsFiltered: any[];
    sentContacts: any;
  } = {
    name: '',
    selectedEmoji: null,
    communityParticipantsFiltered: [],
    sentContacts: [{}],
    isEmojiPickerVisible: false,
  };

  loadingState = {
    global: false,
    connections: false,
  };

  constructor(
    private modalService: NgbModal,
    private dialog: MatDialog,
    private categoryChannelService: CategoryChannelService,
    private toastrService: ToastService
  ) {}

  async ngOnInit(): Promise<void> {
    this.fetchCategoriesData();
  }

  private async fetchCategoriesData() {
    try {
      this.loading = true;

      const [categoriesRes, channelRes] = await Promise.all([
        firstValueFrom(
          this.categoryChannelService.getCategories(this.communityItem.id)
        ),
        firstValueFrom(
          this.categoryChannelService.getAllChannelsByCommunity(
            this.communityItem.id
          )
        ),
      ]);

      // this.communityCategories = categoriesRes.body || [];
      this.communityCategories = (categoriesRes.body || []).map(category => ({
        id: category.id,
        name: category.name,
        createdAt: category.createdAt,
        updatedAt: category.updatedAt,
        community: category.community,
        channels: category.channels,
        channelCount: category.channels ? category.channels.length : 0,
      }));
      const allChannels = channelRes.body || [];
      this.channelsWithoutCategory = allChannels.filter(c => !c.category);
      this.atualizarSideMenu.emit(true);
    } catch (error: any) {
      this.toastrService.show(
        'Ocorreu um erro ao carregar Categorias/Canais. Tente novamente mais tarde.',
        'error'
      );
    } finally {
      this.loading = false;
    }
  }

  openCreateCategoryModal() {
    const dialogRef = this.dialog.open(CreateCategoryModalComponent, {
      data: {
        succes: true,
        communityId: this.communityItem.id,
        allParticipants: this.userConnections,
        allParticipantsEvent: this.allParticipantsEvent,
        allCategories: this.communityCategories,
      },
      height: '80vh',
      width: '80vw',
    });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result?.result) {
          this.fetchCategoriesData();
        }
      },

      error => {
        console.error('Erro ao fechar modal: ', error);
      }
    );
  }

  openCreateChannelModal() {
    const dialogRef = this.dialog.open(CreateChannelModalComponent, {
      data: {
        success: true,
        communityId: this.communityItem.id,
        allParticipants: this.userConnections,
        allCategories: this.communityCategories,
      },
      height: '80vh',
      width: '80vw',
    });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result?.result) {
          this.fetchCategoriesData();
        }
      },

      error => console.error('Erro ao fechar o modal: ', error)
    );
  }

  get hasChannel(): boolean {
    return this.communityCategories.some(category => category.channels.length > 0);
  }

  openEditCategoryModal(category: CategoryInterface) {
    const data: ModalDataInterface = {
      allCategories: this.communityCategories,
      allParticipants: this.userConnections,
      allParticipantsEvent:this.allActiveMembers,
      categoryItem: category,
      channelItem: null,
      communityId: this.communityItem.id,
      modalType: 'category',
      success: false,
      sidebarOption: 'openEditCategory',
      openedFrom: '',
    };

    const dialogRef = this.dialog.open(CreateCategoryModalComponent, {
      data,
      height: '80vh',
      width: '80vw',
    });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result?.result) {
          this.fetchCategoriesData();
          this.loading = true;
        }
      },
      error => {
        console.error('Erro ao fechar o modal:', error);
        this.loading = false;
      }
    );
  }

  openEditChannelModal(channel: ChannelInterface) {
    const data: ModalDataInterface = {
      allCategories: this.communityCategories,
      allParticipants: this.userConnections,
      allParticipantsEvent:this.allActiveMembers,
      categoryItem: null,
      channelItem: channel,
      communityId: this.communityItem.id,
      modalType: 'channel',
      success: false,
      sidebarOption: 'openEditChannel',
      openedFrom: '',
    };

    const dialogRef = this.dialog.open(CreateChannelModalComponent, {
      data,
      height: '80vh',
      width: '80vw',
    });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result?.result) {
          this.fetchCategoriesData();
          this.loading = true;
        }
      },
      error => {
        console.error('Erro ao fechar o modal:', error);
        this.loading = false;
      }
    );

  }

  openDeleteCategoryModal(category: CategoryInterface) {
    const data: ModalDataInterface = {
      allCategories: this.communityCategories,
      allParticipants: this.userConnections,
      allParticipantsEvent:this.allActiveMembers,
      categoryItem: category,
      channelItem: null,
      communityId: this.communityItem.id,
      modalType: 'category',
      success: false,
      sidebarOption: 'openDeleteCategory',
      openedFrom: '',
    };

    const dialogRef = this.dialog.open(DeleteSideMenuOptionComponent, {
      data,
      maxWidth: '500px',
    });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result?.success) {
          this.fetchCategoriesData();
        }
      },
      error => {
        console.error('Erro ao fechar o modal:', error);
        this.loading = false;
      }
    );
  }

  openDeleteChannelModal(channel: ChannelInterface) {
    const data: ModalDataInterface = {
      allCategories: this.communityCategories,
      allParticipants: this.userConnections,
      allParticipantsEvent:this.allActiveMembers,
      categoryItem: null,
      channelItem: channel,
      communityId: this.communityItem.id,
      modalType: 'channel',
      success: false,
      sidebarOption: 'openDeleteChannel',
      openedFrom: '',
    }

    const dialogRef = this.dialog.open(DeleteSideMenuOptionComponent, {
      data,
      maxWidth: '500px',
    });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result?.success) {
          this.fetchCategoriesData();
        }
      },
      error => {
        console.error('Erro ao fechar o modal:', error);
        this.loading = false;
      }
    );
  }

}
