import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  BOLD_BUTTON,
  EditorConfig,
  FONT_SIZE_SELECT,
  FORE_COLOR,
  ITALIC_BUTTON,
  JUSTIFY_CENTER_BUTTON,
  JUSTIFY_LEFT_BUTTON,
  JUSTIFY_RIGHT_BUTTON,
  ORDERED_LIST_BUTTON,
  SEPARATOR,
  UNDO_BUTTON,
  UNORDERED_LIST_BUTTON,
} from 'ngx-simple-text-editor';
import { ToastService } from 'src/app/new-sluper/core/service/toast.service';
import { AnnouncementService } from '../announcement.service';
import { IAnnouncement } from 'src/app/model/announcement.model';

@Component({
  selector: 'app-warning-modal-post',
  templateUrl: './warning-modal-post.component.html',
  styleUrls: ['./warning-modal-post.component.css'],
})
export class WarningModalPostComponent implements OnInit {
  @Input() inputType: 'warning' | 'comment' = 'warning';

  configPage = {
    btnSalvar: 'Publicar',
    tituloPlaceholder: 'Digite um título para o aviso...',
    descriptionPlaceholder: 'Sobre o que você quer falar?'
  };

  config: EditorConfig = {
    placeholder: this.configPage.descriptionPlaceholder,
    buttons: [
      UNDO_BUTTON,
      SEPARATOR,
      BOLD_BUTTON,
      ITALIC_BUTTON,
      FORE_COLOR,
      FONT_SIZE_SELECT,
      JUSTIFY_LEFT_BUTTON,
      JUSTIFY_CENTER_BUTTON,
      JUSTIFY_RIGHT_BUTTON,
      ORDERED_LIST_BUTTON,
      UNORDERED_LIST_BUTTON,
    ],
  };

  inputsData = {
    title: '',
    description: '',
    image: '',
    imageFile: undefined as File | undefined,
    movie: '',
    file: '',
    fileFile: undefined as File | undefined,
  };

  changeImageEvent: File | null = null;
  title = new FormControl('', Validators.required);
  description = new FormControl('', Validators.required);
  id = new FormControl();
  showInputMovie = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { eventId: string | null; isPageSettings: boolean; announcement: IAnnouncement },
    private dialogRef: MatDialogRef<WarningModalPostComponent>,
    private toastrService: ToastService,
    private announcementService: AnnouncementService
  ) { }

  ngOnInit(): void {
    if (this.data.isPageSettings) {
      this.configPage = {
        btnSalvar: 'Salvar',
        tituloPlaceholder: 'Título da página de aviso...',
        descriptionPlaceholder: 'Descrição da página de aviso',
      };
    }
    if (this.data.announcement) {
      this.title.setValue(this.data.announcement.title);
      this.description.setValue(this.data.announcement.description);
      Object.assign(this.inputsData, this.data.announcement);
      this.id.setValue(this.data.announcement.id || null);
    }
  }

  onEditorChange(value: string): void {
    this.description.setValue(value);
    this.inputsData.description = value;
  }

  cancelModal(isSave?: boolean) {
    this.dialogRef.close(isSave);
  }

  handleImageChange(event: any) {
    const file = event.target.files[0];
    if (!file) return;

    const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
    if (!validImageTypes.includes(file.type)) {
      this.toastrService.show('Por favor, selecione uma imagem válida.', 'warning');
      return;
    }

    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.inputsData.imageFile = file;
      this.inputsData.image = e.target.result;
    };
    reader.readAsDataURL(file);
  }

  openPdfInput() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'application/pdf';
    input.onchange = (event: any) => {
      const file = event.target.files[0];
      if (!file) return;

      this.inputsData.fileFile = file;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.inputsData.file = e.target.result;
      };
      reader.readAsDataURL(file);
    };
    input.click();
  }


  handleVideoInput() {
    this.showInputMovie = !this.showInputMovie;
    if (!this.showInputMovie) {
      this.inputsData.movie = '';
    } else {
      this.deleteItem();
    }
  }

  deleteItem() {
    this.inputsData = { ...this.inputsData, image: '', imageFile: undefined, movie: '', file: '', fileFile: undefined };
  }

  templateImageCropped(event: any): void {
    const blob = event.blob;

    if (blob && blob.size) {
      const croppedFile = new File([blob], this.inputsData.imageFile?.name || 'cropped_image.png', {
        type: blob.type,
      });

      const reader = new FileReader();
      reader.onload = (e) => {
        if (!e.target || !e.target.result) return;
        if (typeof e.target.result === 'string') {
          this.showInputMovie = false;
          this.inputsData.imageFile = croppedFile;
          this.inputsData.image = e.target.result;
        }
      };
      reader.readAsDataURL(croppedFile);
    }
  }

  salvar() {
    if (this.title.invalid || this.description.invalid) {
      this.toastrService.show('Preencha todos os campos obrigatórios!', 'warning');
      return;
    }

    if (!this.data.eventId) {
      this.toastrService.show('Erro ao salvar!', 'error');
      return;
    }

    const announcement: IAnnouncement = {
      title: this.title.value || '',
      description: this.description.value || '',
      id: this.id.value || null,
      eventId: this.data.eventId,
      isPageSetting: this.data.isPageSettings,
    };

    this.announcementService.salvar(announcement).subscribe(
      () => this.cancelModal(true),
      () => this.toastrService.show('Erro ao salvar.', 'error')
    );
  }
}
