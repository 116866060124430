import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastService } from '../../service/toast.service';

@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.css']
})
export class ProfilePictureComponent implements OnInit {
  @Input() profileImage: string = '';
  @Input() chosePhotoDirectly?: boolean = false;
  @Output() imageChanged = new EventEmitter<{ newImage: string, file?: File }>();

  showChangeOptions: boolean = false;
  profileImageFile: File | undefined;

  constructor(
    private toastService: ToastService
  ) { }

  ngOnInit(): void {
    if (this.profileImage.length == 0 || this.chosePhotoDirectly) {
      this.toggleChangeProfile();
      this.profileImage = ''
    }
  }
  toggleChangeProfile() {
    this.showChangeOptions = !this.showChangeOptions;
  }

  onChangeProfilePicture() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';

    input.onchange = (event: any) => {
      const file = event.target.files[0];
      if (file) {

        const reader = new FileReader();
        reader.onload = (e: any) => {
          const img = new Image();
          img.onload = () => {
            if (img.width <= 720) { // Verifica se a largura é no máximo 720px
              this.profileImageFile = file; // Armazena o arquivo selecionado
              this.imageChanged.emit({
                newImage: e.target.result,
                file: this.profileImageFile // Inclui o arquivo na emissão do evento
              });
              this.showChangeOptions = false;
            } else {
              this.showChangeOptions = true;
              this.toastService.showError('A imagem deve ter no máximo 720px de largura.');
            }
          };
          img.src = e.target.result; // Define a imagem carregada no objeto Image
        };
        reader.readAsDataURL(file); // Converte o arquivo para base64
      }
    };

    input.click();
  }

}
