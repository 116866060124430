<div id="announcement-card" class="personalized-scroll">
  <div class="page-title">
    <div class="d-flex align-items-center">
      <i class="bi bi-chevron-left pointer" (click)="handleCloseScreen()" (keydown.enter)="handleCloseScreen()"></i>
      <h4 id="mention-title">Vitrine / entrar em contato</h4>
    </div>
  </div>

  <div id="announcement-image">
    <img [src]="advertisement?.banner" alt="Foto da empresa">

    <div class="featured">
      <h4>{{ advertisement?.companyName }}</h4>
    </div>
  </div>

  <div class="d-flex- flex-column about-company">
    <h3>Sobre a empresa</h3>
    <p>{{ advertisement?.description }}</p>
  </div>

  <div class="row">

    <div class="col-sm-12 col-md-12 col-lg-6">
      <div id="announcement-carousel">
        <div class="carousel-container">
          <button class="nav-button left" (click)="prevImage()">
            <i class="bi bi-chevron-left"></i>
          </button>
          <img [src]="images[selectedImage]" alt="Imagem Principal" class="main-image">
          <button class="nav-button right" (click)="nextImage()">
            <i class="bi bi-chevron-right"></i>
          </button>
        </div>

        <div class="thumbnail-container">
          <img *ngFor="let img of images; let i = index" [src]="img" [class.active]="i === selectedImage"
            (click)="selectImage(i)" [alt]="'Imagem ' + (i + 1)" />
        </div>
      </div>

    </div>
    <div class="col-sm-12 col-md-12 col-lg-6">
      <div class="contact-form">
        <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
          <!-- Nome -->
          <app-form-field [control]="getControl('name')" type="text" label="Nome" placeholder="Seu Nome"
            [errorMessages]="{
              required: 'O nome é obrigatório.',
              minlength: 'O nome deve ter no mínimo 3 caracteres.'
            }">
          </app-form-field>

          <!-- Email -->
          <app-form-field [control]="getControl('email')" type="email" label="Email"
            placeholder="ex: seuemail@email.com" [errorMessages]="{
              required: 'O email é obrigatório.',
              email: 'Formato de email inválido.'
            }">
          </app-form-field>

          <!-- Telefone -->
          <app-form-field [control]="getControl('phone')" type="tel" label="Telefone" placeholder="(00) 0000-0000"
            [errorMessages]="{
              required: 'O telefone é obrigatório.',
              pattern: 'O formato do telefone é inválido.'
            }">
          </app-form-field>

          <!-- Objetivo do Contato -->
          <app-form-field [control]="getControl('purpose')" type="select" label="Objetivo do Contato"
            [options]="contactOptions" [errorMessages]="{
              required: 'Por favor, selecione um objetivo de contato.'
            }">
          </app-form-field>

          <!-- Mensagem -->
          <app-form-field [control]="getControl('message')" type="textarea" label="Mensagem"
            placeholder="Escreva sobre uma dúvida ou demanda que deseja orçar." [errorMessages]="{
              required: 'A mensagem é obrigatória.',
              minlength: 'A mensagem deve ter no mínimo 10 caracteres.'
            }">
          </app-form-field>

          <!-- Botão de Enviar -->
          <button type="submit" class="submit-button" [disabled]="contactForm.invalid">Enviar</button>
        </form>
      </div>
    </div>
  </div>
</div>