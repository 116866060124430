import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FormFieldComponent } from './form-field/form-field.component';
import { GenericModalComponent } from './generic-modal/generic-modal.component';
import { ImageUploadModalComponent } from './image-upload-modal/image-upload-modal.component';

@NgModule({
    declarations: [
        FormFieldComponent,
        GenericModalComponent,
        ImageUploadModalComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        ImageCropperModule
    ],
    exports: [
        FormFieldComponent,
        GenericModalComponent,
        ImageUploadModalComponent
    ]
})
export class SharedModule { }
