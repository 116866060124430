import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Advertisement, AdvertisementService } from '../manager-company-advertisement/advertisement.service';

@Component({
  selector: 'app-contact-announcement',
  templateUrl: './contact-announcement.component.html',
  styleUrls: ['./contact-announcement.component.css'],
})
export class ContactAnnouncementComponent implements OnInit {
  @Output() closeScreen = new EventEmitter<void>();
  @Input() advertisementId!: any;
  advertisement: Advertisement | null = null;
  images: string[] = [];
  selectedRating: number = 0;
  selectedImage: number = 0;

  contactForm!: FormGroup;
  contactOptions = [
    { value: 'orcamento', label: 'Solicitação de orçamento' },
    { value: 'parceria', label: 'Parceria comercial' },
    { value: 'suporte', label: 'Suporte Técnico' },
    { value: 'informacoes', label: 'Informações sobre produtos/serviços' },
    { value: 'outros', label: 'Outros' }
  ];

  constructor(private fb: FormBuilder, private advertisementService: AdvertisementService) { }

  ngOnInit(): void {
    // Inicialização do formulário reativo
    this.contactForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.pattern(/^\(\d{2}\) \d{4,5}-\d{4}$/)]],
      purpose: ['', Validators.required],
      message: ['', [Validators.required, Validators.minLength(10)]]
    });

    // Carregar os dados do anúncio
    this.loadAdvertisementData();
  }

  onSubmit(): void {
    if (this.contactForm.valid) {
      console.log('Formulário enviado:', this.contactForm.value);
    } else {
      console.error('Formulário inválido.');
    }
  }

  loadAdvertisementData(): void {
    if (!this.advertisementId) return;

    this.advertisementService.getAdvertisementById(this.advertisementId).subscribe({
      next: (data) => {
        // Corrigido para atribuir diretamente o objeto retornado
        this.advertisement = data;

        // Processar as imagens do anúncio
        this.images = [
          ...(data.imagesCompany?.map(String) || []), // Garantir que as imagens sejam strings
          ...(data.banner ? [String(data.banner)] : []) // Adicionar o banner como imagem, se existir
        ];
      },
      error: (error) => {
        console.error('Erro ao carregar anúncio:', error);
      },
    });
  }

  handleCloseScreen(): void {
    this.closeScreen.emit();
  }

  prevImage(): void {
    this.selectedImage =
      this.selectedImage === 0
        ? this.images.length - 1
        : this.selectedImage - 1;
  }

  nextImage(): void {
    this.selectedImage =
      this.selectedImage === this.images.length - 1
        ? 0
        : this.selectedImage + 1;
  }

  selectImage(index: number): void {
    this.selectedImage = index;
  }

  getControl(controlName: string): FormControl {
    return this.contactForm.get(controlName) as FormControl;
  }
}
