<div class="form-group">
    <label *ngIf="label" class="form-label">{{ label }}</label>

    <ng-container [ngSwitch]="type">
        <!-- Campo de texto -->
        <input *ngSwitchCase="'text'" [formControl]="control" [placeholder]="placeholder" class="form-control"
            [ngClass]="{'is-invalid': control.touched && control.invalid}" />

        <!-- Campo de textarea -->
        <textarea *ngSwitchCase="'textarea'" [formControl]="control" [placeholder]="placeholder" class="form-control"
            [ngClass]="{'is-invalid': control.touched && control.invalid}">
        </textarea>

        <!-- Campo de seleção (select) -->
        <select *ngSwitchCase="'select'" [formControl]="control" class="form-control"
            [ngClass]="{'is-invalid': control.touched && control.invalid}">
            <option *ngFor="let option of options" [value]="option.value">{{ option.label }}</option>
        </select>

        <!-- Campo de seleção múltipla -->
        <select *ngSwitchCase="'multiselect'" [formControl]="control" class="form-control" multiple
            [ngClass]="{'is-invalid': control.touched && control.invalid}" (change)="onMultiSelectChange($event)">
            <option *ngFor="let option of options" [value]="option.value" [selected]="isSelected(option.value)">
                {{ option.label }}
            </option>
        </select>

        <!-- Campo padrão -->
        <input *ngSwitchDefault [formControl]="control" [type]="type" [placeholder]="placeholder" class="form-control"
            [ngClass]="{'is-invalid': control.touched && control.invalid}" />
    </ng-container>

    <!-- Mensagem de erro -->
    <span class="error-message" *ngIf="errorMessage">
        {{ errorMessage }}
    </span>
</div>