<div class="modal-overlay">
    <div class="modal-content">
        <h2>{{ title }}</h2>
        <p>{{ message }}</p>

        <div class="modal-buttons">
            <button class="confirm-button" (click)="confirmAction()">{{ confirmText }}</button>
            <button *ngIf="showCancel" class="cancel-button" (click)="closeModal()">{{ cancelText }}</button>
        </div>
    </div>
</div>