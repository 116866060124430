<div class="container-fluid" id="company-advertisement">
    <div class="page-title">
        <div class="d-flex align-items-center">
            <i class="bi bi-chevron-left"></i>
            <h4 id="mention-title">Vitrine / adicionar empresa</h4>
        </div>

        <div class="buttons-container">
            <button class="outline-button" (click)="handleCloseCompanyAdvertisement()">Cancelar</button>
            <button class="highlight-button" (click)="submitForm()">Salvar</button>
        </div>
    </div>

    <form [formGroup]="advertisementForm" class="form">
        <!-- Banner -->
        <div class="input-item input-banner">
            <h4 class="company-advertisement-title">Adicione o banner para o anúncio</h4>

            <!-- Se não tiver imagem, exibe botão para adicionar -->
            <div class="card-banner" *ngIf="!imageItems.image" (click)="openImageModal()">
                <i class="bi bi-image"></i>
                <span>Adicionar banner</span>
            </div>

            <!-- Se houver imagem, exibe a prévia -->
            <ng-container *ngIf="imageItems.image">
                <img [src]="imageItems.image" alt="Foto do banner" class="image-banner"
                    (click)="openImageSelectionModal()">
            </ng-container>

            <div *ngIf="advertisementForm.get('banner')?.touched && advertisementForm.get('banner')?.invalid"
                class="error-message">
                Banner é obrigatório.
            </div>
        </div>


        <!-- Toggle -->
        <div class="input-item input-toggle">
            <h4 class="company-advertisement-title">Anúncio destaque?</h4>
            <ng-toggle [labels]="toggleConfig.labels" [switchColor]="toggleConfig.switchColor"
                [color]="toggleConfig.color" [(ngModel)]="toggleConfig.value" [disabled]="toggleConfig.disabled"
                [fontColor]="toggleConfig.fontColor" [textAlign]="toggleConfig.textAlign"
                [fontSize]="toggleConfig.fontSize" [width]="toggleConfig.width" [height]="toggleConfig.height"
                [margin]="toggleConfig.margin" formControlName="premium">
            </ng-toggle>
        </div>

        <div class="row">
            <!-- Coluna Esquerda -->
            <div class="col-lg-6">
                <app-form-field [control]="getControl('companyName')" type="text" label="Nome da empresa"
                    placeholder="Ex: Sluper" [errorMessages]="{ required: 'Nome da empresa é obrigatório.' }">
                </app-form-field>

                <app-form-field [control]="getControl('description')" type="textarea" label="Descrição"
                    placeholder="Digite a descrição"
                    [errorMessages]="{ minlength: 'Descrição deve ter no mínimo 10 caracteres.' }">
                </app-form-field>

                <app-form-field [control]="getControl('contact')" type="text" label="Contato"
                    placeholder="Email ou telefone"
                    [errorMessages]="{ required: 'Informe um email ou número de telefone válido.' }">
                </app-form-field>

                <div>
                    <h4 class="company-advertisement-title">Slides</h4>
                    <div id="drag-and-drop-container">
                        <!-- Foto de capa -->
                        <div class="image-upload cover-photo" (click)="handleCoverPhotoUpload()"
                            (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)"
                            (drop)="onDropCoverPhoto($event)">
                            <ng-container *ngIf="coverPhoto">
                                <img [src]="coverPhoto" alt="Foto de capa" class="uploaded-image" />
                            </ng-container>
                            <ng-container *ngIf="!coverPhoto">
                                <i class="bi bi-image"></i>
                                <span>Adicionar foto de capa (Clique ou arraste)</span>
                            </ng-container>
                        </div>
                        <input type="file" #coverPhotoInput (change)="onCoverPhotoSelected($event)" hidden
                            accept="image/*" />

                        <!-- Upload de múltiplas imagens -->
                        <div class="image-upload additional-photos" (click)="handleAdditionalPhotosUpload()"
                            (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)"
                            (drop)="onDropAdditionalPhotos($event)">
                            <i class="bi bi-plus"></i>
                            <span>Adicionar uma ou mais fotos (Clique ou arraste)</span>
                        </div>
                        <input type="file" #additionalPhotosInput (change)="onAdditionalPhotosSelected($event)" multiple
                            hidden accept="image/*" />
                    </div>

                    <!-- Exibição das imagens carregadas -->
                    <div class="uploaded-images">
                        <div *ngFor="let photoUrl of additionalPhotosUrls; let i = index" class="uploaded-photo">
                            <button type="button" class="btn-remove-photo" (click)="removeAdditionalPhoto(i)">
                                <i class="bi bi-trash"></i>
                            </button>
                            <img [src]="photoUrl" alt="Foto adicional" class="uploaded-image" />
                        </div>
                    </div>
                </div>
            </div>

            <!-- Coluna Direita -->
            <div class="col-lg-6">
                <!-- Período de Exibição -->
                <div class="input-item input-time">
                    <h4 class="company-advertisement-title">Período de exibição</h4>
                    <div class="instructions-container date">
                        <app-form-field [control]="getControl('startDate')" type="date" label="Início do período"
                            [errorMessages]="{ invalidStartDate: 'A data inicial deve ser igual ou posterior à data atual.' }">
                        </app-form-field>

                        <app-form-field [control]="getControl('endDate')" type="date" label="Fim do período"
                            placeholder="Deixe vazio se não houver fim"
                            [errorMessages]="{ invalidEnd: 'A data final não pode ser anterior à data inicial.' }">
                        </app-form-field>
                    </div>
                </div>

                <!-- Horário -->
                <div class="input-item input-time">
                    <h4 class="company-advertisement-title">Horário</h4>
                    <div class="instructions-container">
                        <app-form-field [control]="getControl('startTime')" type="time" label="Horário de início"
                            [errorMessages]="{ invalidStart: 'O horário inicial deve ser válido e no futuro.' }">
                        </app-form-field>

                        <app-form-field [control]="getControl('endTime')" type="time" label="Horário de fim"
                            [errorMessages]="{ invalidEnd: 'O horário final não pode ser anterior ao inicial.' }">
                        </app-form-field>
                    </div>
                </div>

                <app-form-field [control]="getControl('category')" type="select" label="Categoria"
                    [options]="occupationsListMapped" [errorMessages]="{ required: 'Categoria é obrigatória.' }">
                </app-form-field>

                <app-form-field [control]="getControl('region')" type="select" label="Região"
                    [options]="citiesListMapped" [errorMessages]="{ required: 'Região é obrigatória.' }">
                </app-form-field>
            </div>
        </div>
    </form>
</div>

<app-image-upload-modal #imageUploadModal [title]="'Selecionar Imagem'" [confirmText]="'Confirmar'"
    [removeText]="'Remover'" [cropMode]="imageConfig.cropMode" [maxWidth]="1080" [maxHeight]="1080"
    [imageEvent]="imageItems.temporaryEvent" (imageSelected)="fileChangeEventImage($event)"
    (imageCroppedEvent)="imageCropped($event)" (imageRemoved)="removeSelectedImage()"
    (modalClosed)="confirmImageSelection()">
</app-image-upload-modal>