import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

interface User {
  name: string;
  category: string;
  status: number;
  situation: string;
  price: number;
  limitDate: string;
  selected: boolean;
}

@Component({
  selector: 'app-community-manager-finance',
  templateUrl: './community-manager-finance.component.html',
  styleUrls: ['./community-manager-finance.component.css']
})
export class CommunityManagerFinanceComponent implements OnInit, AfterViewInit {
  public dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  selectedSituation: string = '';
  searchName: string = '';
  situations: string[] = ['Pago', 'Débito', 'Pendente'];

  public users: User[] = [
    {
      name: 'João Silva',
      category: 'Comum',
      status: 0,
      situation: 'Pago',
      price: 29.99,
      limitDate: '2025-02-01',
      selected: false
    },
    {
      name: 'Maria Oliveira',
      category: 'VIP',
      status: 1,
      situation: 'Débito',
      price: 99.99,
      limitDate: '2025-01-15',
      selected: false
    },
    {
      name: 'Carlos Santos',
      category: 'Comum',
      status: 2,
      situation: 'Pendente',
      price: 29.99,
      limitDate: '2025-01-25',
      selected: false
    },
    {
      name: 'Ana Costa',
      category: 'VIP',
      status: 0,
      situation: 'Pago',
      price: 99.99,
      limitDate: '2025-03-01',
      selected: false
    },
    {
      name: 'Pedro Almeida',
      category: 'Comum',
      status: 1,
      situation: 'Débito',
      price: 29.99,
      limitDate: '2025-01-10',
      selected: false
    },
    {
      name: 'Beatriz Souza',
      category: 'VIP',
      status: 2,
      situation: 'Pendente',
      price: 99.99,
      limitDate: '2025-01-20',
      selected: false
    }
  ];

  displayedColumns: string[] = ['select', 'name', 'category', 'status', 'situation', 'price', 'limitDate', 'actions'];

  allSelected = false;

  constructor() {
    this.dataSource = new MatTableDataSource<User>();

    this.dataSource.filterPredicate = (data: User, filter: string) => {
      const searchTerms = JSON.parse(filter);
      const matchesName = data.name.toLowerCase().includes(searchTerms.name);
      const matchesCategory = searchTerms.category ? data.situation.toLowerCase() === searchTerms.category : true;

      return matchesName && matchesCategory;
    };
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.users);
    this.updateFilter();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }


  toggleAllSelection(): void {
    this.allSelected = !this.allSelected;
    this.dataSource.data.forEach(user => user.selected = this.allSelected);
  }

  checkSelection(): void {
    this.allSelected = this.dataSource.data.every(user => user.selected);
  }

  applyNameFilter() {
    this.updateFilter();
  }

  applyCategoryFilter() {
    this.updateFilter();
  }

  updateFilter() {
    let filteredData = this.users;

    if (this.searchName.trim()) {
      filteredData = filteredData.filter(user =>
        user.name.toLowerCase().includes(this.searchName.trim().toLowerCase())
      );
    }

    if(this.selectedSituation) {
      filteredData = filteredData.filter(user =>
        user.situation.toLowerCase() === this.selectedSituation.toLowerCase()
      );
    }

    this.dataSource.data = filteredData;
  }

  enableUser(user: any) {
    user.status = 0;
    user.situation = 'Pago';
  }

  unableUser(user: any) {
    user.status = 1;
    user.situation = 'Débito';
  }

  sendPaymentEmail(user: any) {
    // Implementar lógica de envio de email
  }


  getSituationClass(situation: string): string {
    switch (situation) {
      case 'Pago':
        return 'situation-paid';
      case 'Débito':
        return 'situation-debt';
      case 'Pendente':
        return 'situation-pending';
      default:
        return '';
    }
  }

  getButtonClass(item: any) {
    if (item.status === 0) {
      return 'class-for-status-0';
    } else if (item.status === 1) {
      return 'class-for-status-1';
    } else {
      return '';
    }
  }

}
