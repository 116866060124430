import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Connection } from 'src/app/model/connection.model';
import { DocumentUserProfile } from 'src/app/model/document-user-profile.model';
import { EventParticipant, SluperEvent } from 'src/app/model/event.model';
import { IUserProfilePlan } from 'src/app/model/user-plan.model';

@Component({
  selector: 'app-community-settings',
  templateUrl: './community-settings.component.html',
  styleUrls: ['./community-settings.component.css']
})
export class CommunitySettingsComponent implements OnInit {
  @Input() isUserCommunityOwner: boolean = false
  @Input() userProfile: DocumentUserProfile | undefined
  @Input() communityItem!: SluperEvent
  @Input() userProfilePlan!: IUserProfilePlan;
  @Input() userConnections!: Connection[];
  @Input() allParticipantsEvent!: EventParticipant[];
  @Output() atualizarSideMenu = new EventEmitter<boolean>();

  public selectedTab: 'community-information' | 'community-members' | 'community-channels-categories' | 'community-posts' | 'profile' = 'community-members';
  constructor() { }

  ngOnInit(): void {
  }


  public changeSelectedTab(tab: 'community-information' | 'community-members' | 'community-channels-categories' | 'community-posts' | 'profile') {
    this.selectedTab = tab;
  }
}
