<app-loading-spinner [customBg]="'transparent'" *ngIf="loading"></app-loading-spinner>


<div class="category-container" *ngIf="screen === ''">
  <div class="category-header">
    <h3>Perfil de membros</h3>
    <button class="add-members-btn" (click)="openCreateCategoryMembersModal()">
      <i class="bi bi-plus"></i>
      Criar Perfil de membros
    </button>
  </div>

  <!-- ENVOLVENDO A TABELA EM UMA DIV PARA SCROLL HORIZONTAL -->
  <div class="table-wrapper">
    <table class="member-categories-table" *ngIf="categories.length">
      <thead>
        <tr>
          <th>Nome do perfil de membros</th>
          <th>Data</th>
          <th>Qtd. Membros</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let cat of categories">
          <td>{{ cat.name }}</td>
          <td>{{ cat.createdAt | date : 'dd/MM/yyyy' }}</td>
          <td>{{ cat.quantity }}</td>
          <td>
            <div class="d-flex align-items-center">
              <div class="member-categories-delete-option mx-1" (keydown)="openRemoveCategory(cat.id)"
                (click)="openRemoveCategory(cat.id)">
              </div>
              <div class="member-categories-edit-option mx-1" (keydown)="handleOpenEditCategory(cat)"
                (click)="handleOpenEditCategory(cat)">
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <h3 class="no-items" *ngIf="!categories.length">Nenhuma perfil de membros cadastrada</h3>
  </div>
</div>

<div *ngIf="screen === 'edit-member-category'">
  <app-edit-member-category [editCategoryItem]="editCategoryItem" [userProfile]="userProfile"
    [allCommunityMembers]="allActiveMembers" [communityItem]="communityItem"
    (refreshCategories)="handleRefreshCategories()" (back)="handleBackMembersScreen()"
    (removeCategory)="handleRemoveCategory($event)"></app-edit-member-category>
</div>