import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CategoryInterface, ChannelInterface } from './../categoryAndChannel/category-channel.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css'],
})
export class SideMenuComponent {
  private _communityCategories: CategoryInterface[] = [];
  private initialized = false;

  @Input()
  set communityCategories(value: CategoryInterface[]) {
    this._communityCategories = value;
    if (value.length > 0 && !this.initialized) {
      this.initialized = true;
      this.initializeSelection();
    }
  }
  get communityCategories(): CategoryInterface[] {
    return this._communityCategories;
  }

  @Input() channelsWithoutCategory: ChannelInterface[] = [];
  @Output() openSideMenuOption = new EventEmitter<any>();
  @Output() createChannel = new EventEmitter<any>();
  @Output() selectChannel = new EventEmitter<any>();
  @Output() selectScreen = new EventEmitter<any>();
  @Input() isAdmin = false;

  private openSections: Set<string> = new Set([]);
  public openChannelsWithoutCategory: boolean = false;
  public selectedChannel: ChannelInterface | null = null;

  private initializeSelection(): void {
    if (this.communityCategories.length > 0) {
      for (const category of this.communityCategories) {
        if (category.channels && category.channels.length > 0) {
          this.selectedChannel = category.channels[0];
          this.toggleSection(category.id);
          break;
        }
      }
    }

    if (!this.selectedChannel && this.channelsWithoutCategory.length > 0) {
      this.selectedChannel = this.channelsWithoutCategory[0];
      this.openChannelsWithoutCategory = true;
    }

    if (this.selectedChannel) {
      this.onSelectChannel(this.selectedChannel);
    }
  }

  toggleSection(id: string): void {
    if (this.openSections.has(id)) {
      this.openSections.delete(id);
    } else {
      this.openSections.add(id);
    }
  }

  isSectionOpen(id: string): boolean {
    return this.openSections.has(id);
  }

  openSideMenuOptionsModal(item: CategoryInterface | ChannelInterface, type: 'category' | 'channel') {
    const data = {
      allCategories: this.communityCategories,
      modalType: type,
      categoryItem: type === 'category' ? item : null,
      channelItem: type === 'channel' ? item : null,
    };
    this.openSideMenuOption.emit(data);
  }

  onSelectChannel(channel: ChannelInterface) {
    this.selectedChannel = channel;
    this.selectChannel.emit(channel);
  }

  onChannelClick(type: 'category' | 'channel') {
    const data = {
      modalType: type
    };
    this.createChannel.emit(data);
  }


  handleSelectScreen(screen: 'initial' | 'mentions' | 'saved' | 'alerts' | 'settings' | 'finance' | 'store') {
    if (screen === 'initial') {
      this.selectedChannel = null;
      this.selectChannel.emit(null);
    }
    this.selectScreen.emit(screen);
  }

}
