import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { BOLD_BUTTON, EditorConfig, FONT_SIZE_SELECT, FORE_COLOR, ITALIC_BUTTON, SEPARATOR, UNDO_BUTTON } from 'ngx-simple-text-editor';
import { firstValueFrom } from 'rxjs';
import { CategoryChannelService, CategoryInterface, CreateChannelData, ModalDataInterface } from 'src/app/categoryAndChannel/category-channel.service';
import { ToastService } from 'src/app/new-sluper/core/service/toast.service';

@Component({
  selector: 'app-create-channel-modal',
  templateUrl: './create-channel-modal.component.html',
  styleUrls: ['./create-channel-modal.component.css'],
})
export class CreateChannelModalComponent implements OnInit {
  @ViewChild('selfClosingAlert', { static: false }) selfClosingAlert: NgbAlert | undefined;

  public modalType: string = 'create';
  public loading = false;
  public isEmojiPickerVisible = false;
  public channelForm!: FormGroup;
  public isFormSubmitted = false;
  public descriptionText = '';
  public categories: CategoryInterface[] = [];

  changedImageEvent: any = null;
  imageChangedEvent: any = null;
  croppedImage: any = '';
  imageToCrop = '';
  cropMode = false;
  imageChanged = false;

  config: EditorConfig = {
    placeholder: '',
    buttons: [UNDO_BUTTON, SEPARATOR, BOLD_BUTTON, ITALIC_BUTTON, FORE_COLOR, FONT_SIZE_SELECT]
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalDataInterface,
    private dialogRef: MatDialogRef<CreateChannelModalComponent>,
    private fb: FormBuilder,
    private categoryChannelService: CategoryChannelService,
    private toastrService: ToastService
  ) {
    this.modalType = data.sidebarOption === 'openEditChannel' ? 'edit' : 'create';
  }

  async ngOnInit() {
    this.categories = this.data.allCategories;
    this.initializeForm();
    if (this.modalType === 'edit') {
      this.setEditFormValues();
    } else {
      this.channelForm.get('banner')?.addValidators([Validators.required])
    }
    if (this.data.openedFrom === 'createCategory') {
      this.channelForm.get('category')?.disable();
    }
  }

  initializeForm() {
    this.channelForm = this.fb.group({
      channelName: ['', Validators.required],
      emoji: ['', Validators.required],
      category: ['', Validators.required],
      description: ['', Validators.required],
      banner: ['']
    });
  }

  setEditFormValues() {
    if (this.data?.channelItem) {
      this.channelForm.patchValue({
        channelName: this.data.channelItem.name,
        emoji: this.data.channelItem.icon,
        category: this.data.channelItem.category,
        description: this.data.channelItem.description,
        banner: this.data.channelItem.bannerUrl
      });

      this.descriptionText = this.data.channelItem.description;
      this.channelForm.get('category')?.disable();

      if (typeof this.data.channelItem.bannerUrl === 'string') {
        this.loadImageFromUrl(this.data.channelItem.bannerUrl);
      }
    }
  }

  async loadImageFromUrl(url: string) {
    try {
      const res = await fetch(url);
      if (!res.ok) throw new Error('Erro ao buscar imagem');

      const blob = await res.blob();
      const timestamp = Date.now();
      const extension = blob.type === 'image/png' ? 'png' : 'jpg';
      const file = new File([blob], `banner_${timestamp}.${extension}`, { type: blob.type });

      this.channelForm.get('banner')?.setValue(file);
      this.imageToCrop = URL.createObjectURL(file); // se quiser usar depois no crop
    } catch (error) {
      console.error('Erro ao carregar imagem para crop:', error);
      this.toastrService.show('Não foi possível carregar a imagem do canal para edição.', 'error');
    }
  }

  countTextChars(text: string): number {
    return text?.replace(/<[^>]*>/g, '').length;
  }

  handleImageCropped(event: ImageCroppedEvent) {
    if (event.blob) {
      const timestamp = Date.now();
      const extension = event.blob.type === 'image/png' ? 'png' : 'jpg';
      const file = new File([event.blob], `banner_${timestamp}.${extension}`, { type: event.blob.type });
      this.channelForm.get('banner')?.setValue(file);
    }
  }

  handleFileChange(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.cropMode = true;
      this.imageToCrop = e.target?.result as string;
      this.changedImageEvent = event;
    };
    reader.readAsDataURL(file);
    this.imageChanged = true;
  }

  removeSelectedImage() {
    this.cropMode = false;
  }

  toggleEmojiPicker() {
    this.isEmojiPickerVisible = !this.isEmojiPickerVisible;
  }

  addEmoji(event: any) {
    const emojiValue = event.native || event.emoji?.native || event.emoji || 'Emoji não encontrado';
    this.channelForm.controls['emoji'].setValue(emojiValue);
    this.channelForm.controls['emoji'].markAsTouched();
    this.isEmojiPickerVisible = false;
  }

  async saveChannel() {

    this.channelForm.get('description')?.setValue(this.descriptionText);

    this.isFormSubmitted = true;
    this.channelForm.markAllAsTouched();

    if (this.channelForm.invalid) {
      console.error('O formulário está inválido.');
      return;
    }

    if (this.data.openedFrom === 'createCategory') {
      this.closeModal(true, 'Atualizado com sucesso!', this.prepareChannelData());
      return;
    }

    try {
      this.loading = true;

      const data: CreateChannelData = {
        id: this.data.channelItem?.id || null,
        name: this.channelForm.value.channelName,
        community: this.data.communityId,
        category: this.channelForm.value.category,
        icon: this.channelForm.value.emoji,
        description: this.channelForm.value.description,
        banner: this.channelForm.value.banner
      };

      if (this.modalType === 'edit') {
        await firstValueFrom(this.categoryChannelService.editChannel(data));
        this.toastrService.show('Canal editado com sucesso.', 'success');
        this.closeModal(true, 'Canal editado com sucesso!', this.prepareChannelData());
      } else {
        await firstValueFrom(this.categoryChannelService.createChannel(data));
        this.toastrService.show('Canal criado com sucesso.', 'success');
        this.closeModal(true, 'Canal criado com sucesso!', this.prepareChannelData());
      }
    } catch (error) {
      this.toastrService.show(
        this.modalType === 'edit' ? 'Erro ao editar canal.' : 'Erro ao criar canal.',
        'error'
      );
    }
    finally {
      this.loading = false;
    }
  }

  prepareChannelData() {
    return {
      channelName: this.channelForm.value.channelName,
      emoji: this.channelForm.value.emoji,
      category: this.channelForm.value.category,
      description: this.channelForm.value.description,
      banner: this.channelForm.value.banner,
    };
  }

  closeModal(result: boolean, message: string, channel?: any) {
    this.dialogRef.close({ result, message, channel });
  }
}

