import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { IDocumentPreferences } from 'src/app/model/preferences.model';
import { ToastService } from 'src/app/new-sluper/core/service/toast.service';
import { ImageUploadModalComponent } from 'src/app/shared/image-upload-modal/image-upload-modal.component';
import { ICidade, UserProfileService } from 'src/app/user-profile/user-profile.service';
import { emailOrPhoneValidator, endDateValidator, minLengthValidator, startDateValidator } from 'src/app/utils/validators';
import { IMAGE_CONFIG, TOGGLE_CONFIG } from './advertisement.config';
import { AdvertisementService } from './advertisement.service';

@Component({
  selector: 'app-manager-company-advertisement',
  templateUrl: './manager-company-advertisement.component.html',
  styleUrls: ['./manager-company-advertisement.component.css']
})
export class ManagerCompanyAdvertisementComponent implements OnInit {
  @Input() advertisementId: any;

  @Output() closeCompanyAdvertisement = new EventEmitter();
  @Output() submittedCompanyAdvertisement = new EventEmitter();

  @ViewChild('imageSelectionModal') imageSelectionModal: any;
  @ViewChild('coverPhotoInput') coverPhotoInput!: ElementRef;
  @ViewChild('additionalPhotosInput') additionalPhotosInput!: ElementRef;

  @ViewChild('imageUploadModal') imageUploadModal!: ImageUploadModalComponent;

  occupationsList!: IDocumentPreferences[];
  occupationsListMapped: { value: string; label: string }[] = [];

  citiesList!: ICidade[];
  citiesListMapped: { value: string; label: string }[] = [];

  openImageModal(): void {
    if (this.imageUploadModal) {
      this.imageUploadModal.openModal();
    } else {
      console.error('Modal não foi carregado corretamente.');
    }
  }

  public toggleConfig = TOGGLE_CONFIG;
  public imageConfig = IMAGE_CONFIG;

  advertisementForm: FormGroup;

  coverPhoto: string | File | null = null;
  additionalPhotos: (string | File)[] = [];

  additionalPhotosFiles: File[] = [];
  additionalPhotosUrls: string[] = [];

  public imageItems: {
    image: string | null,
    imageEvent: string | null,
    imageBlob: File | null,
    temporary: string | null,
    temporaryEvent: string | null,
    temporaryBlob: File | null,
  } = {
      image: null,
      imageEvent: null,
      imageBlob: null,
      temporary: null,
      temporaryEvent: null,
      temporaryBlob: null
    };

  userId: string = '';
  communityId: string = '';

  constructor(
    private fb: FormBuilder,
    private toastrService: ToastService,
    private modalService: NgbModal,
    private advertisementService: AdvertisementService,
    private userProfileService: UserProfileService
  ) {
    this.advertisementForm = this.fb.group({
      banner: [null, Validators.required],
      premium: [false],
      companyName: ['', Validators.required],
      description: ['', [Validators.required, minLengthValidator(10)]],
      contact: ['', [Validators.required, emailOrPhoneValidator]],
      imagesCompany: [[], Validators.required],
      startDate: [null, [Validators.required, startDateValidator]],
      endDate: [null],
      startTime: [null, Validators.required],
      endTime: [null],
      category: ['', Validators.required],
      region: ['Belo Horizonte', Validators.required],
      communityId: [null],
    });
  }

  ngOnInit(): void {
    const sessionProfile = this.userProfileService.getUserSessionProfile();
    this.userId = sessionProfile?.userId as string;
    this.communityId = sessionProfile?.communityId as string;

    this.getPreferences();
    this.getCidadesDeMinasGerais();
    this.loadAdvertisementData();
    this.setupValidators();
    this.setupPremiumToggleListener();
    this.setupFormValueChanges();
  }

  handleCloseCompanyAdvertisement(): void {
    this.closeCompanyAdvertisement.emit();
  }

  private logInvalidControls(): void {
    const invalidControls: { controlName: string; errors: any }[] = [];

    Object.keys(this.advertisementForm.controls).forEach(controlName => {
      const control = this.advertisementForm.get(controlName);
      if (control && control.invalid) {
        invalidControls.push({ controlName, errors: control.errors });
      }
    });
  }

  submitForm(): void {
    if (this.advertisementForm.invalid) {
      this.advertisementForm.markAllAsTouched();
      this.logInvalidControls();
      return;
    }

    if (this.additionalPhotosFiles.length > 0) {
      this.advertisementForm.get('imagesCompany')?.setValue(this.additionalPhotosFiles);
    }

    const advertisementData = this.advertisementForm.value;

    advertisementData.createdBy = this.userId;
    advertisementData.communityId = this.communityId;

    if (this.advertisementId) {
      this.advertisementService.updateAdvertisement(this.advertisementId, advertisementData).subscribe({
        next: (response) => {
          this.toastrService.show('Anúncio atualizado com sucesso!', 'success');
          this.submittedCompanyAdvertisement.emit(response.body);
        },
        error: () => {
          this.toastrService.show('Erro ao atualizar anúncio. Tente novamente.', 'error');
        }
      });
    } else {
      this.advertisementService.createAdvertisement(advertisementData).subscribe({
        next: (response) => {
          this.toastrService.show('Anúncio criado com sucesso!', 'success');
          this.submittedCompanyAdvertisement.emit(response.body);
        },
        error: () => {
          this.toastrService.show('Erro ao criar anúncio. Tente novamente.', 'error');
        }
      });
    }
  }

  openImageSelectionModal(): void {
    this.imageConfig.isSelectionImageModalOpen = true;
    this.imageConfig.cropMode = false;
    this.imageItems.imageEvent = null;

    const modalRef = this.modalService.open(this.imageSelectionModal, {
      size: 'lg',
      centered: true,
    });

    modalRef.result
      .then(() => {
        this.imageConfig.isSelectionImageModalOpen = false;
      })
      .catch(() => {
        this.imageConfig.isSelectionImageModalOpen = false;
      });
  }

  fileChangeEventImage(event: any): void {
    const file: File = event.target.files[0];
    if (!file) {
      console.error('Nenhum arquivo selecionado');
      return;
    }

    const reader = new FileReader();

    reader.onload = (e: any) => {
      const image = new Image();
      image.src = e.target.result as string;

      image.onload = () => {
        this.imageConfig.cropMode = true;
        this.imageItems.temporaryEvent = event;
        this.imageConfig.imageUpdated = true;
      };
    };

    reader.readAsDataURL(file);
  }

  imageCropped(event: ImageCroppedEvent): void {
    if (event.blob && event.blob.size) {
      const currentTime = Date.now();
      const fileName = `image${currentTime}.png`;

      this.imageItems.temporaryBlob = new File([event.blob], fileName, {
        type: 'image/png',
        lastModified: currentTime,
      });
    }
  }

  confirmImageSelection(): void {
    if (!this.imageItems.temporaryBlob) {
      return;
    }

    this.imageItems.imageBlob = this.imageItems.temporaryBlob;
    this.imageItems.image = URL.createObjectURL(this.imageItems.imageBlob);

    this.advertisementForm.get('banner')?.setValue(this.imageItems.imageBlob);
    this.modalService.dismissAll();
    this.imageConfig.isSelectionImageModalOpen = false
  }


  removeSelectedImage(): void {
    this.imageConfig.cropMode = false;
    this.imageItems.image = '';
    this.imageItems.imageEvent = null;
    this.imageConfig.imageUpdated = false;
  }

  handleImagesUpload(event: any): void {
    const files: FileList = event.target.files;
    if (!files || files.length === 0) return;

    const images: File[] = Array.from(files);
    const currentImages = this.advertisementForm.get('imagesCompany')?.value || [];
    this.advertisementForm.get('imagesCompany')?.setValue([...currentImages, ...images]);
  }

  handleCoverPhotoUpload(): void {
    this.coverPhotoInput.nativeElement.click();
  }

  onCoverPhotoSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];

    if (file instanceof File) {
      this.coverPhoto = URL.createObjectURL(file);
    } else {
      console.error('O arquivo selecionado não é válido.');
    }
  }

  handleAdditionalPhotosUpload(): void {
    this.additionalPhotosInput.nativeElement.click();
  }

  onAdditionalPhotosSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    const files = input.files;

    if (files && files.length > 0) {
      const newFiles = Array.from(files);


      this.additionalPhotosFiles.push(...newFiles);


      const urls = newFiles.map(file => URL.createObjectURL(file));
      this.additionalPhotosUrls.push(...urls);

      this.advertisementForm.get('imagesCompany')?.setValue([...this.additionalPhotosUrls]);
    }
  }

  onDropAdditionalPhotos(event: DragEvent): void {
    event.preventDefault();
    const files = event.dataTransfer?.files;

    if (files && files.length > 0) {
      const currentImages = this.advertisementForm.get('imagesCompany')?.value || [];
      const newFiles = Array.from(files).map(file => {
        return new File([file], file.name, { type: file.type, lastModified: file.lastModified });
      });

      this.advertisementForm.get('imagesCompany')?.setValue([...currentImages, ...newFiles]);

      this.additionalPhotos.push(...newFiles.map(file => URL.createObjectURL(file)));
    }
  }

  removeAdditionalPhoto(index: number): void {

    URL.revokeObjectURL(this.additionalPhotosUrls[index]);


    this.additionalPhotosUrls.splice(index, 1);
    this.additionalPhotosFiles.splice(index, 1);


    const currentImages = this.advertisementForm.get('imagesCompany')?.value || [];
    currentImages.splice(index, 1);
    this.advertisementForm.get('imagesCompany')?.setValue(currentImages);
  }


  onDragLeave(event: DragEvent): void {
    const target = event.target as HTMLElement;
    target.classList.remove('drag-over');
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault();
    const target = event.target as HTMLElement;
    target.classList.add('drag-over');
  }

  private processFile(file: File, callback: (result: string) => void): void {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      callback(e.target.result);
    };
    reader.readAsDataURL(file);
  }


  onDropCoverPhoto(event: DragEvent): void {
    event.preventDefault();
    const file = event.dataTransfer?.files[0];
    if (file) {
      this.processFile(file, (result: string) => {
        this.coverPhoto = result;
      });
    }
    const target = event.target as HTMLElement;
    target.classList.remove('drag-over');
  }

  private loadAdvertisementData(): void {
    if (this.advertisementId) {
      this.advertisementService.getAdvertisementById(this.advertisementId).subscribe({
        next: (response) => {
          this.advertisementForm.patchValue(response);

          if (response.banner) {
            if (typeof response.banner === 'string') {
              this.imageItems.image = response.banner;
            } else if (response.banner instanceof File) {
              this.imageItems.image = URL.createObjectURL(response.banner);
            }
            this.advertisementForm.get('banner')?.setValue(response.banner);
          } else {
            this.imageItems.image = null;
          }
          const images = response.imagesCompany || [];
          if (images.length > 0) {
            this.coverPhoto = images[0];
            this.additionalPhotosUrls = images.slice(1).map((image: any) => image);
          } else {
            this.coverPhoto = null;
            this.additionalPhotosUrls = [];
          }

          this.advertisementForm.get('imagesCompany')?.setValue(images);
        },
        error: () => {
          this.toastrService.show('Erro ao buscar dados do anúncio.', 'error');
        }
      });
    }
  }

  getPreferences() {
    this.userProfileService
      .getPreferencesRemoveResponse()
      .subscribe({
        next: (response) => {
          if (response !== null) {
            this.occupationsList = response.filter(o => o.type === 'AREA_ATUACAO');
            this.occupationsList.sort((a, b) => a.description.localeCompare(b.description));
          }

          this.occupationsListMapped = this.occupationsList?.map(occupation => ({
            value: occupation.id.toString(),
            label: occupation.description
          }));
        },
        error: (res) => console.error(res),
      });
  }

  getCidadesDeMinasGerais() {
    this.userProfileService
      .getCidadesDeMinasGerais()
      .subscribe({
        next: (response) => {
          if (response !== null) {
            this.citiesList = response.sort((a, b) => a.nome.localeCompare(b.nome));
          }

          this.citiesListMapped = this.citiesList?.map(occupation => ({
            value: occupation.id.toString(),
            label: occupation.nome
          }));
        },
        error: (res) => console.error(res),
      });
  }

  private setupValidators(): void {
    const startDateControl = this.advertisementForm.get('startDate');
    const endDateControl = this.advertisementForm.get('endDate');
    const endTimeControl = this.advertisementForm.get('endTime');

    if (startDateControl && endDateControl) {
      endDateControl.setValidators([endDateValidator(startDateControl)]);

      startDateControl.valueChanges.subscribe(() => {
        endDateControl.updateValueAndValidity();
      });
    }

    endDateControl?.valueChanges.subscribe((endDate) => {
      if (!endDate) {
        endTimeControl?.disable();
        endTimeControl?.setValue(null);
      } else {
        endTimeControl?.enable();
      }
    });
  }

  private setupPremiumToggleListener(): void {
    this.advertisementForm.get('premium')?.valueChanges.subscribe((value) => {
      this.toggleConfig.value = value;
    });
  }

  private setupFormValueChanges(): void {
    const startDateControl = this.advertisementForm.get('startDate');
    const startTimeControl = this.advertisementForm.get('startTime');
    const endDateControl = this.advertisementForm.get('endDate');
    const endTimeControl = this.advertisementForm.get('endTime');

    this.advertisementForm.valueChanges.subscribe(() => {
      const startDate = startDateControl?.value;
      const startTime = startTimeControl?.value;
      const endDate = endDateControl?.value;
      const endTime = endTimeControl?.value;


      this.validateStartDate(startDateControl, startDate);


      this.validateStartTime(startDate, startTime, startTimeControl);


      this.validateEndTime(startDate, startTime, endDate, endTime, endTimeControl);
    });
  }

  private validateStartDate(control: AbstractControl | null, startDate: string | null): void {
    if (!control || !startDate) return;

    const today = new Date().toISOString().split('T')[0];
    const startDateStr = new Date(startDate).toISOString().split('T')[0];


    if (startDateStr < today) {
      control.setErrors({ invalidStartDate: true });
    } else {
      control.setErrors(null);
    }
  }

  private validateStartTime(startDate: string | null, startTime: string | null, control: AbstractControl | null): void {
    if (!control || !startDate || !startTime) return;

    const today = new Date();
    const startDateObj = new Date(startDate);

    if (startDateObj.toDateString() === today.toDateString()) {
      const now = new Date();
      const [hours, minutes] = startTime.split(':').map(Number);
      const startTimeObj = new Date(today.getFullYear(), today.getMonth(), today.getDate(), hours, minutes);

      if (startTimeObj <= now) {
        control.setErrors({ invalidStartTime: true });
      } else {
        control.setErrors(null);
      }
    } else {
      control.setErrors(null);
    }
  }


  private validateEndTime(
    startDate: string | null,
    startTime: string | null,
    endDate: string | null,
    endTime: string | null,
    control: AbstractControl | null
  ): void {
    if (!control || !startDate || !startTime || !endDate || !endTime) return;

    const startDateTime = new Date(`${startDate}T${startTime}`);
    const endDateTime = new Date(`${endDate}T${endTime}`);

    if (endDateTime < startDateTime) {
      control.setErrors({ invalidEndTime: true });
    } else {
      control.setErrors(null);
    }
  }

  getControl(controlName: string): FormControl {
    return (this.advertisementForm.get(controlName) as FormControl) || new FormControl();
  }
}
