import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChannelInterface } from 'src/app/categoryAndChannel/category-channel.service';
import { Connection } from 'src/app/model/connection.model';
import { DocumentUserProfile } from 'src/app/model/document-user-profile.model';
import { EventParticipant, SluperEvent } from 'src/app/model/event.model';
import { IUserProfilePlan } from 'src/app/model/user-plan.model';
import { DeleteWarningModalComponent } from './delete-warning-modal/delete-warning-modal.component';
import { WarningModalPostComponent } from './warning-modal-post/warning-modal-post.component';
import { IAnnouncement } from 'src/app/model/announcement.model';
import { AnnouncementService } from './announcement.service';
import { LoadingService } from 'src/app/new-sluper/core/service/loading.service';
import { ToastService } from 'src/app/new-sluper/core/service/toast.service';

@Component({
  selector: 'app-community-warnings',
  templateUrl: './community-warnings.component.html',
  styleUrls: ['./community-warnings.component.css'],
})
export class CommunityWarningsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() isUserCommunityOwner: boolean = false;
  @Input() userProfile: DocumentUserProfile | undefined;
  @Input() communityItem!: SluperEvent;
  @Input() selectedChannel!: ChannelInterface | null;
  @Input() userProfilePlan!: IUserProfilePlan;
  @Input() userConnections!: Connection[];
  @Input() participants: EventParticipant[] = [];

  announcements: IAnnouncement[] = [];
  announcementSetting: IAnnouncement | undefined;

  private buttonClickListener: any;
  selectedRange: any = null;
  calendarLocale = {
    applyLabel: 'Aplicar',
    cancelLabel: 'Limpar',
    clearLabel: 'Limpar',
    customRangeLabel: 'Intervalo Personalizado',
  };

  titlePage: string = '';
  descriptionPage: string = '';

  constructor(private renderer: Renderer2,
      private el: ElementRef,
      private dialog: MatDialog,
      private announcementService: AnnouncementService,
      private toastService: ToastService,
      private loadingService:LoadingService
  ) { }

  ngOnInit(): void {
    this.listarAvisos();
    this.getSettings();
  }

  ngAfterViewInit(): void {
    this.addButtonClickListener();
  }

  addButtonClickListener(): void {
    let button = this.el.nativeElement.querySelector();

    if (button) {
      this.buttonClickListener = this.renderer.listen(button, 'click', () => {
        this.handleClearDate();
      });
    } else {
      setTimeout(() => {
        this.addButtonClickListener();
      }, 1000);
    }
  }

  ngOnDestroy(): void {
    if (this.buttonClickListener) {
      this.buttonClickListener();
    }
  }

  handleClearDate(): void {
    this.selectedRange = null;    
    this.listarAvisos();
  }

  onDateChosen(event: any) {
    
    if (!event.startDate || !event.endDate) {
      this.handleClearDate();
    } else {
      const startDate = event.startDate.toDate ? event.startDate.toDate() : new Date(event.startDate);
      const endDate = event.endDate.toDate ? event.endDate.toDate() : new Date(event.endDate);
    
      this.filtrarByDate(startDate, endDate);
    }
  }

  openWarningPost(isPageSettings:boolean, announcement?:IAnnouncement) {
    const dialogRef = this.dialog.open(WarningModalPostComponent, {
      width: '600px',
      data: {
        eventId: this.communityItem.id,
        isPageSettings:isPageSettings,
        announcement:announcement
      },
    });

    dialogRef.afterClosed().subscribe(
      async (result) => {
        if(result){
          isPageSettings ?  this.getSettings() : this.listarAvisos();
        }
      },
      (error) => {
        console.error('Erro ao fechar modal: ', error);
      }
    )
  }

  openDeleteWarning(avisoId:number | undefined) {
    const dialogRef = this.dialog.open(DeleteWarningModalComponent, {
      width: '600px',
      data:{avisoId:avisoId}
    });

    dialogRef.afterClosed().subscribe(
      async (result) => {
        if(result){
          this.listarAvisos();
        }
      },
      (error) => {
        console.error('Errp ao fechar modal: ', error);
      }
    )
  }

  listarAvisos(){
    this.loadingService.show();
    this.announcementService.listarAvisosByeventId(this.communityItem.id).subscribe({
      next: (response) => {
        this.announcements = response.body ? response.body : [];
        this.loadingService.hide();
      },
      error: (err) => {
        const errMsg = err ? err : 'Aconteceram alguns erros ao tentar recuperar os avisos da comunidade!';
        this.toastService.showError(errMsg)
        this.loadingService.hide();
      }
    })
  }

  getSettings(){
    this.loadingService.show();
    this.announcementService.getSettingsAvisosByeventId(this.communityItem.id).subscribe({
      next: (response) => {
        this.descriptionPage = response.body?.description || '';
        this.titlePage = response.body?.title || '';
        this.announcementSetting = response.body || undefined;
        this.loadingService.hide();

      },
      error: (err) => {
        const errMsg = err ? err : 'Aconteceram alguns erros ao tentar recuperar os avisos da comunidade!';
        this.toastService.showError(errMsg)
        this.loadingService.hide();
      }
    })
  }

  filtrarByDate(startDate:Date,endDate:Date){
    this.loadingService.show();
    this.announcementService.filtrarAvisosByDate(this.communityItem.id,startDate,endDate).subscribe({
      next: (response) => {
        this.announcements = response.body ? response.body : [];
        this.loadingService.hide();
      },
      error: (err) => {
        const errMsg = err ? err : 'Aconteceram alguns erros ao tentar filtrar os avisos da comunidade!';
        this.toastService.showError(errMsg);
        this.loadingService.hide();
      }
    })
  }

  getAnnouncementsByMonth(): { [key: string]: IAnnouncement[] } {
    return this.announcements.reduce((acc, announcement) => {
      if (announcement.createDate) {
        const date = new Date(announcement.createDate);
        const monthYear = date.toLocaleString('default', { month: 'long', year: 'numeric' });
        
        if (!acc[monthYear]) {
          acc[monthYear] = [];
        }
        acc[monthYear].push(announcement);
      }
      return acc;
    }, {} as { [key: string]: IAnnouncement[] });
  }
}
