<div class="dashboard-card-new" *ngIf="dashboardCardData.percentage != null && dashboardCardData.difference != null; else cardSimples">
  <div class="dashboard-card-header-new">
    <i class="bi {{ dashboardCardData.icon }} dashboard-card-icon-new"></i>
    <span class="dashboard-card-title-new">{{ dashboardCardData.title }}</span>
  </div>
  <div class="dashboard-card-value-container-new">
    <span class="dashboard-card-value-new">{{ dashboardCardData.value }}</span>
    <div class="dashboard-card-percentage-new">
      <span class="percentage-value"
            [ngClass]="{ 'positive': dashboardCardData.percentage > 0, 'negative': dashboardCardData.percentage < 0 }">
        <i *ngIf="dashboardCardData.percentage > 0" class="bi bi-arrow-up"></i>
        <i *ngIf="dashboardCardData.percentage < 0" class="bi bi-arrow-down"></i>
        {{ dashboardCardData.percentage | number: '1.1-1' }}%
      </span>
    </div>
  </div>
  <div class="dashboard-card-comparison-new">
    <span class="comparison-value"
          [ngClass]="{ 'positive': dashboardCardData.difference >= 0, 'negative': dashboardCardData.difference < 0 }">
      {{ dashboardCardData.difference >= 0 ? '+' : '' }}{{ dashboardCardData.difference }}
    </span>
    <span>do que no último mês</span>
  </div>
</div>
<ng-template #cardSimples>
  <div class="stat-item card-white">
    <div class="stat-item-left">
      <i class="bi {{ dashboardCardData.icon }} dashboard-card-icon-new"></i>
    </div>
    <div class="stat-item-right">
        <span>{{dashboardCardData.title}}</span>
        <h4>
          {{dashboardCardData.value || 0}}
          <i class="bi bi-percent dashboard-card-icon-new" *ngIf="dashboardCardData.isPercent"></i>
        </h4>
    </div>
  </div>
</ng-template>
