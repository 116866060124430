import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom } from 'rxjs';
import { EventsService } from 'src/app/events/events.service';

import { IConnection } from 'src/app/model/connection.model';
import { IDocumentUserProfileWithPreferences } from 'src/app/model/document-user-profile.model';

@Component({
  selector: 'app-indicate-resources',
  templateUrl: './indicate-resources.component.html',
  styleUrls: ['./indicate-resources.component.css'],
})
export class IndicateResourcesModalComponent implements OnInit {
  @Input() idUser = '';
  @Input() idUserIndication = '';
  @Input() nameUserIndication = '';
  @Input() connections: IConnection[] = [];
  connectionsProfiles: IDocumentUserProfileWithPreferences[] = [];
  selectedConnections: IDocumentUserProfileWithPreferences[] = [];
  screenHeight: any;
  screenWidth: any;

  connectionForm = new FormControl([]);
  execute = false;
  filterValue: string = '';
  connectionsFiltered: IDocumentUserProfileWithPreferences[] = [];

  constructor(private activeModal: NgbActiveModal, private service: EventsService) { }

  ngOnInit(): void {
    this.connections.forEach(c => this.connectionsProfiles.push(c.connection));
    this.connectionsFiltered = this.connectionsProfiles;
    return;
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  async sendConnection() {
    if (this.selectedConnections.length > 0 && this.idUserIndication !== '' && this.idUser !== '') {
      this.execute = true;
      const listIdToIndication: string[] = this.selectedConnections.map(connection => connection.idUser);
      try {
        await firstValueFrom(this.service.sendIndication(
          this.idUser,
          this.idUserIndication,
          listIdToIndication,
        ));
      } catch (error) {
        console.error(error);
        this.execute = false;
        return;
      }
    }
    this.execute = false;
    this.closeModal(true);
  }

  closeModal(status: boolean) {
    this.activeModal.close(status);
  }

  filter(event: any) {
    if (event.target.value.length === 0) {
      this.connectionsFiltered = this.connectionsProfiles;
    } else {
      this.connectionsFiltered = this.connectionsProfiles.filter((person: IDocumentUserProfileWithPreferences) => person.name.toLowerCase().includes(event.target.value.toLowerCase()));
    }
  }

  verify(person: IDocumentUserProfileWithPreferences): boolean {
    return this.selectedConnections.includes(person);
  }

  add(person: IDocumentUserProfileWithPreferences) {
    this.selectedConnections.push(person);

  }

  remove(person: IDocumentUserProfileWithPreferences) {
    this.selectedConnections.splice(this.selectedConnections.indexOf(person), 1);
  }

  handleSelectEverybody() {
    this.selectedConnections = this.connectionsFiltered;
    this.sendConnection();
  }

  handleSelect() {
    if (this.selectedConnections.length === 0) return;
    this.sendConnection();
  }
}
