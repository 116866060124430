<div class="warning-modal-post">
  <div class="warning-post-container">
    <div class="warning-post">
      <input
        *ngIf="inputType === 'warning'"
        [formControl]="title"
        type="text"
        [placeholder]="this.configPage.tituloPlaceholder"
        class="warning-title"/>
    </div>
    <div class="editor-container">
      <st-editor
        style="width: 100%;"
        [(ngModel)]="inputsData.description"
        (ngModelChange)="onEditorChange($event)"
        [config]="config">
      </st-editor>
      <span class="error-message" *ngIf="(description.value?.length === 0 || description.invalid) && description.touched">
        A descrição é obrigatória.
      </span>
    </div>
    <!-- <div class="warning-post-attachment" *ngIf="inputType === 'warning' && !this.data.isPageSettings">
      <div class="warning-attachment">
        <i class="bi bi-images" (click)="imageInput.click()"></i>
        <i class="bi bi-camera-video" (click)="handleVideoInput()"></i>
        <i class="bi bi-paperclip" (click)="openPdfInput()"></i>
        <input
          #imageInput
          type="file"
          accept="image/*"
          style="display: none;"
          (change)="handleImageChange($event)"/>
      </div>
    </div> -->

    <!-- <div class="input-data" *ngIf="inputType === 'warning' && !this.data.isPageSettings">
      <div class="input-data-item" *ngIf="inputsData.image">
        <image-cropper
          [imageChangedEvent]="changeImageEvent"
          [maintainAspectRatio]="true"
          [aspectRatio]="16 / 9"
          format="png"
          (imageCropped)="templateImageCropped($event)"
          [cropperMaxWidth]="720"></image-cropper>
        <i (click)="deleteItem()" class="bi bi-x-lg positioned-absolute"></i>
      </div>
      <div class="input-data-item" *ngIf="inputsData.file">
        <i id="imageFileInput" class="bi bi-file-earmark-arrow-down"></i>
        <label class="multi-line" for="imageFileInput">
          {{ inputsData.fileFile?.name }}
        </label>
        <i (click)="deleteItem()" class="bi bi-trash-fill"></i>
      </div>
      <div class="input-data-item" *ngIf="showInputMovie">
        <input
          placeholder="Insira o link do video"
          type="url"
          id="imageMovieInput"
          [(ngModel)]="inputsData.movie"
        />
        <i (click)="deleteItem(); showInputMovie = false" class="bi bi-trash-fill"></i>
      </div>
    </div> -->

    <div class="warning-post-buttons">
      <button type="button" class="cancel-button" (click)="cancelModal(false)">
        Cancelar
      </button>
      <button type="button" class="save-button" (click)="salvar()">
        {{this.configPage.btnSalvar}}
      </button>
    </div>
  </div>
</div>