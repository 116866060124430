import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-dashboard-card-new',
  templateUrl: './dashboard-card-new.component.html',
  styleUrls: ['./dashboard-card-new.component.css']
})
export class DashboardCardNewComponent {
  @Input() dashboardCardData: {
    value: any;
    title: string;
    color: string;
    icon: string;
    percentage?: number | null;
    difference?: number | null;
    isPercent?:Boolean | null;
  } = {
    value: 0,
    title: '',
    color: '',
    icon: '',
    percentage: 0,
    difference: 0,
    isPercent:false
  };
}
