<app-loading-spinner [bgTransparent]="true" *ngIf="loading"></app-loading-spinner>

<div class="modal-container">
  <header class="modal-header">
    <div class="d-flex w-100">
      <i (click)="selectedTab > 0 && backStep()" [class.disabled]="selectedTab === 0"
        class="bi bi-arrow-left pointer"></i>
      <h4 class="modal-title" *ngIf="!updateMode" translate>events.createEvent.title</h4>
      <h4 class="modal-title" *ngIf="updateMode" translate>events.updateEvent.title</h4>
    </div>
    <section class="tab-selector">
      <div id="event-tab-selector">
        <div [class]="selectedTab === 0 ? 'tab selected-tab pointer' : 'tab pointer'"
          (click)="changeSelectedTabStep(0)">
          <h2 class="tab-title">Informações do Evento</h2>
        </div>

        <div [class]="selectedTab === 1 ? 'tab selected-tab pointer' : 'tab pointer'"
          (click)="changeSelectedTabStep(1)">
          <h2 class="tab-title">Descrição</h2>
        </div>

        <div [class]="selectedTab === 2 ? 'tab selected-tab pointer' : 'tab pointer'"
          (click)="changeSelectedTabStep(2)">
          <h2 class="tab-title">Ingressos</h2>
        </div>
      </div>
      <div class="modal-header-options">
        <button (click)="closeModal()" class="cancel-event-button">Cancelar</button>
        <button [disabled]="loading" (click)="nextStep()" *ngIf="!updateMode" class="save-event">{{selectedTab === 2 ?
          'Publicar' :
          'Proximo'}}</button>
        <button [disabled]="loading" (click)="nextStep()" *ngIf="updateMode" class="save-event">{{selectedTab === 2?
          'Atualizar' :
          'Proximo'}}</button>
      </div>
    </section>
  </header>

  <section class="modal-body">
    <section class="event-fields-section">
      <h4 class="modal-title" *ngIf="screens === 'information'">Insira as primeiras informações para criar seu Evento
      </h4>
      <div class="input-item input-banner">
        <h4 class="create-event-title">Adicione o banner</h4>

        <!-- Se não tiver imagem, exibe botão para adicionar -->
        <div class="card-banner" *ngIf="!imageItems.image" (click)="openImageModal()">
            <i class="bi bi-image"></i>
            <span>Adicionar banner</span>
        </div>

        <!-- Se houver imagem, exibe a prévia -->
        <ng-container *ngIf="imageItems.image">
            <img [src]="imageItems.image" alt="Foto do banner" class="image-banner"
                (click)="openImageSelectionModal()">
        </ng-container>
      </div>

      <div class="event-fields-container" *ngIf="screens === 'information'">
        <div class="input-container grid-full">
          <label for="event-name" class="input-label"> Nome do evento* </label>

          <div class="input-group event-input-container">
            <input maxlength="50" [style]="!formErrors.name.valid && formErrors.name.message ? 'border-color: red' : ''"
              [(ngModel)]="modalData.name" id="event-name" placeholder="Nome do evento" type="text"
              class="form-control event-input" />
          </div>

          <span class="error-message" *ngIf="!formErrors.name.valid && formErrors.name.message">
            {{ formErrors.name.message }}
          </span>
        </div>

        <div class="row-input-container">
          <div class="input-container">
            <label for="event-end-time" class="input-label"> Data do evento* </label>
            <div class="input-group event-input-container date-picker-wrapper">
              <input type="date" [(ngModel)]="modalData.startDate" id="event-end-time" placeholder="Início do evento"
                [min]="minDate" class="form-control event-input" (input)="validateDate('start' , $event)"
                [style]="!formErrors.startDate.valid && formErrors.startDate ? 'border-color: red' : ''" />
            </div>
            <span class="error-message" *ngIf="!formErrors.startDate.valid && formErrors.startDate.message">
              {{ formErrors.startDate.message }}
            </span>
          </div>
          <h3>à</h3>
          <div class="input-container">
            <label for="event-end-time" class="input-label invisible">Fim do evento</label>
            <div class="input-group event-input-container date-picker-wrapper">
              <input type="date" [(ngModel)]="modalData.endDate" id="event-end-time" placeholder="Fim do evento"
                [min]="minEndDate" (input)="validateDate('end' , $event)" class="form-control event-input"
                [style]="!formErrors.endDate.valid && formErrors.endDate ? 'border-color: red' : ''" />
            </div>
            <span class="error-message" *ngIf="!formErrors.endDate.valid && formErrors.endDate.message">
              {{ formErrors.endDate.message }}
            </span>
            <span class="tip-message" *ngIf="formErrors.endDate.valid">
              Caso haja apenas uma data, deixe este campo vazio
            </span>
          </div>
        </div>


        <div class="row-input-container">
          <div class="input-container">
            <label for="event-start-time" class="input-label">Horário*</label>
            <div class="input-group event-input-container">
              <input type="time" [(ngModel)]="modalData.startTime" id="event-start-time" placeholder="Início do evento"
                [min]="minStartTime" class="form-control event-input" (input)="validateTime('start', $event)"
                [style]="!formErrors.startTime.valid && formErrors.startTime ? 'border-color: red' : ''" />
            </div>
            <span class="error-message" *ngIf="!formErrors.startTime.valid && formErrors.startTime.message">
              {{ formErrors.startTime.message }}
            </span>
            <span class="tip-message" *ngIf="formErrors.startDate.valid">
              Início
            </span>
          </div>
          <h3>à</h3>
          <div class="input-container">
            <label for="event-end-time" class="input-label invisible">Fim do evento</label>
            <div class="input-group event-input-container">
              <input type="time" [(ngModel)]="modalData.endTime" id="event-end-time" placeholder="Fim do evento"
                [min]="minEndTime" class="form-control event-input" (input)="validateTime('end', $event)"
                [style]="!formErrors.endTime.valid && formErrors.endTime ? 'border-color: red' : ''" />
            </div>
            <span class="error-message" *ngIf="!formErrors.endTime.valid && formErrors.endTime.message">
              {{ formErrors.endTime.message }}
            </span>
            <span class="tip-message" *ngIf="formErrors.endTime.valid">
              Fim
            </span>
          </div>
        </div>


        <div class="toggles grid-full">
          <div class="toggle-container">
            <label class="input-label" for="public-event">Evento Público</label>

            <div class="form-check form-switch">
              <input [(ngModel)]="modalData.public" id="public-event" type="checkbox" role="switch"
                class="form-check-input" name="active" />
            </div>
          </div>

          <div class="toggle-container">
            <label class="input-label" for="public-event">Requer Aprovação</label>

            <div class="form-check form-switch">
              <input [(ngModel)]="modalData.requiresConfirmation" id="public-event" type="checkbox" role="switch"
                class="form-check-input" name="active" />
            </div>
          </div>

          <div class="toggle-container">
            <label class="input-label" for="participant-details-visibility">Exibir detalhes dos participantes somente
              após
              o
              check-in</label>

            <div class="form-check form-switch">
              <input [(ngModel)]="modalData.participantDetailsAfterCheckin" id="participant-details-visibility"
                type="checkbox" role="switch" class="form-check-input" name="active" />
            </div>
          </div>
        </div>

        <div class="input-container" *ngIf="modalData.type === 'PRESENCIAL'">
          <label for="event-address" class="input-label"> CEP* </label>
          <div class="input-group event-input-container">
            <input maxlength="50" [mask]="'00000-000'" (input)="validateZipCode()"
              [style]="!formErrors.zipCode.valid && formErrors.zipCode.message ? 'border-color: red' : ''"
              class="form-control" [(ngModel)]="modalData.zipCode" />
          </div>
          <span class="error-message" *ngIf="!formErrors.zipCode.valid && formErrors.zipCode.message">
            {{ formErrors.zipCode.message }}
          </span>
        </div>

        <div class="input-container" *ngIf="modalData.type === 'PRESENCIAL'">
          <label for="event-address" class="input-label"> Rua* </label>
          <div class="input-group event-input-container">
            <input maxlength="50"
              [style]="!formErrors.address.valid && formErrors.address.message ? 'border-color: red' : ''"
              class="form-control" [(ngModel)]="modalData.address" />
          </div>
          <span class="error-message" *ngIf="!formErrors.address.valid && formErrors.address.message">
            {{ formErrors.address.message }}
          </span>
        </div>

        <div class="input-container" *ngIf="modalData.type === 'PRESENCIAL'">
          <label for="event-address" class="input-label"> Bairro* </label>
          <div class="input-group event-input-container">
            <input maxlength="50"
              [style]="!formErrors.neighborhood.valid && formErrors.neighborhood.message ? 'border-color: red' : ''"
              class="form-control" [(ngModel)]="modalData.neighborhood" />
          </div>
          <span class="error-message" *ngIf="!formErrors.neighborhood.valid && formErrors.neighborhood.message">
            {{ formErrors.neighborhood.message }}
          </span>
        </div>

        <div class="input-container" *ngIf="modalData.type === 'PRESENCIAL'">
          <label for="event-address" class="input-label"> N°* </label>
          <div class="input-group event-input-container">
            <input min="0" max="99999" type="number"
              [style]="!formErrors.number.valid && formErrors.number.message ? 'border-color: red' : ''"
              class="form-control" [(ngModel)]="modalData.number" />
          </div>
          <span class="error-message" *ngIf="!formErrors.number.valid && formErrors.number.message">
            {{ formErrors.number.message }}
          </span>
        </div>

        <div class="input-container" *ngIf="modalData.type === 'PRESENCIAL'">
          <label for="event-address" class="input-label"> Cidade* </label>
          <div class="input-group event-input-container">
            <input maxlength="15" [style]="!formErrors.city.valid && formErrors.city.message ? 'border-color: red' : ''"
              class="form-control" [(ngModel)]="modalData.city" />
          </div>
          <span class="error-message" *ngIf="!formErrors.city.valid && formErrors.city.message">
            {{ formErrors.city.message }}
          </span>
        </div>

        <div class="input-container" *ngIf="modalData.type === 'PRESENCIAL'">
          <label for="event-address" class="input-label"> UF* </label>
          <div class="input-group event-input-container">
            <input maxlength="2"
              [style]="!formErrors.state.valid && formErrors.state.message ? 'border-color: red' : ''"
              class="form-control" [(ngModel)]="modalData.state" />
          </div>
          <span class="error-message" *ngIf="!formErrors.state.valid && formErrors.state.message">
            {{ formErrors.state.message }}
          </span>
        </div>

        <div class="input-container" *ngIf="modalData.type === 'PRESENCIAL'">
          <label for="event-address" class="input-label"> País* </label>
          <div class="input-group event-input-container">
            <select class="form-select event-input" name="paisTelefoneCorporativo" [(ngModel)]="modalData.country"
              [style]="!formErrors.country.valid && formErrors.country.message ? 'border-color: red' : ''">
              <option value=""></option>
              <option [ngValue]="pais.nomePais" *ngFor="let pais of paises">{{ pais.nomePais }}</option>
            </select>
          </div>
          <span class="error-message" *ngIf="!formErrors.country.valid && formErrors.country.message">
            {{ formErrors.country.message }}
          </span>
        </div>

        <div class="input-container grid-full" *ngIf="modalData.type === 'REMOTO'">
          <label for="event-name" class="input-label"> Link da conferência* </label>

          <div class="input-group event-input-container">
            <input maxlength="50" [style]="!formErrors.site.valid && formErrors.site.message ? 'border-color: red' : ''"
              [(ngModel)]="modalData.site" id="event-name" placeholder="Url" type="url" class="form-control event-input"
              (input)="validateUrl()" />
          </div>

          <span class="error-message" *ngIf="!formErrors.site.valid && formErrors.site.message">
            {{ formErrors.site.message }}
          </span>
        </div>

      </div>

      <div class="event-fields-container" *ngIf="screens === 'description'">
        <div class="description-textarea-container grid-full">
          <label for="event-description" class="input-label"> Descrição do evento </label>
          <st-editor
            [style.border]="!formErrors.description.valid || countTextChars(modalData.description) > 1500 ? '1px solid red' : ''"
            [style.border-radius]="'6px'" [(ngModel)]="modalData.description" [config]="config"></st-editor>
          <div class="count-items">
            <span [ngClass]="countTextChars(modalData.description) > 1500 ? 'error-message' : ''"> {{
              countTextChars(modalData.description) }} / 1500 </span>
          </div>
          <span class="error-message" *ngIf="!formErrors.description.valid && formErrors.description.message">
            {{ formErrors.description.message }}
          </span>
        </div>

        <div class="description-textarea-container grid-full">
          <label for="event-description" class="input-label"> Regras do evento </label>
          <st-editor
            [style.border]="!formErrors.about.valid || countTextChars(modalData.about) > 1500 ? '1px solid red' : ''"
            [style.border-radius]="'6px'" [(ngModel)]="modalData.about" [config]="configRules"></st-editor>
          <div class="count-items">
            <span [ngClass]="countTextChars(modalData.about) > 1500 ? 'error-message' : ''"> {{
              countTextChars(modalData.about) }} / 1500 </span>
          </div>
          <span class="error-message" *ngIf="!formErrors.about.valid && formErrors.about.message">
            {{ formErrors.about.message }}
          </span>
        </div>
      </div>

      <div class="input-container" *ngIf="screens === 'ticket'">
        <div class="input-group event-input-container align-center">
          <label for="maximum-participants" class="input-label"> Quantidade de pessoas* </label>
          <i class="btn bi bi-dash-square-fill quantity-people-button" (click)="rmQuantityPerson()"></i>
          <span class="event-input-quantity">{{modalData.maxAttendees }}</span>
          <i class="btn bi bi-plus-square-fill quantity-people-button" (click)="addQuantityPerson()"></i>
        </div>
        <span class="error-message" *ngIf="!formErrors.maxAttendees.valid && formErrors.maxAttendees.message">
          {{ formErrors.maxAttendees.message }}
        </span>
      </div>
      <div style="display: flex; width: 100%"></div>
    </section>

    <section class="event-fields-section" *ngIf="screens === 'ticket'">
      <div class="input-container">
        <div class="search-person-container">
          <label class="input-label"> Adicionar participantes da comunidade: </label>
          <div class="d-flex">
            <span>
              Importe um arquivo CSV
            </span>
            <button *ngIf="!csvFile" (click)="triggerFileInput()" class="highlight-button">Selecionar</button>
          </div>
          <div class="d-flex" *ngIf="csvFile">
            <i class="bi bi-file-earmark-arrow-down" style="font-size: 1.5rem; color: black;">
            </i>
            <span>
              {{ csvFile!.name }}
            </span>

            <button (click)="removeFile()" class="highlight-button-cancel">
              Cancelar
            </button>
            <!-- <button (click)="sendInviteByCsv()" class="highlight-button">
              Enviar
            </button> -->

          </div>
          <div class="d-flex">
            <input type="email" [(ngModel)]="formModel.email" (ngModelChange)="validateEmail()" maxlength="50"
              placeholder="exemplo@gmail.com" class="form-control w-50" />
            <!-- <button [disabled]="!formModel.isValid" (click)="addManualParticipant()" class="highlight-button">
            </button> -->
            <i style="font-size: 30px;" (click)="!formModel.isValid  ? '' : addManualParticipant()"
              class="bi bi-plus-square-fill pointer"></i>
          </div>
          <span class="error-message" *ngIf="formModel.errorMessage">
            {{ formModel.errorMessage }}
          </span>


          <section class="manual-invite-container">
            <div class="manual-invite-item px-3 mb-3" *ngFor="let participant of manualParticipants">
              <span class="request-profession">{{participant}}</span>
              <i class="btn bi bi-dash-square-fill quantity-people-button"
                (click)="rmManualParticipant(participant)"></i>
            </div>
          </section>

        </div>
        <div class="search-person-container" *ngIf="communityParticipantsFiltered.length">
          <input maxlength="50" (input)="filterCommunityParticipants($event)" placeholder="Procurar pessoas"
            class="form-control w-50" />
        </div>
        <div class="people-container">
          <div class="connection-request-card" *ngFor="let person of communityParticipantsFiltered">
            <!-- <img [src]="person.contact.photo" alt=""> -->

            <div class="request-info">
              <div class="info">
                <span class="request-name">{{person.connection.name}}</span>
                <span class="request-profession multi-line"
                  [innerHTML]="person.connection.presentation">{{person.connection.presentation}}</span>
              </div>
            </div>
            <i *ngIf="verifyCommunityParticipants(person)" class="btn bi bi-dash-square-fill quantity-people-button"
              (click)="rmCommunityParticipants(person)"></i>
            <i *ngIf="!verifyCommunityParticipants(person)" class="btn bi bi-plus-square-fill quantity-people-button"
              (click)="addCommunityParticipants(person)"></i>
          </div>
        </div>
      </div>
    </section>
  </section>
</div>

<app-image-upload-modal #imageUploadModal [title]="'Selecionar Imagem'" [confirmText]="'Confirmar'"
    [removeText]="'Remover'" [cropMode]="imageConfig.cropMode" [maxWidth]="1080" [maxHeight]="1080"
    [imageEvent]="imageItems.temporaryEvent" (imageSelected)="fileChangeEventImage($event)"
    (imageCroppedEvent)="imageCropped($event)" (imageRemoved)="removeSelectedImage()"
    (modalClosed)="confirmImageSelection()">
</app-image-upload-modal>

<ng-template #imageSelectionModal>
  <img [src]="imageItems.image" width="800px" height="600px" alt="Visualização da imagem selecionada">
</ng-template>