import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { IMembersCategories, MembersCategoriesService } from 'src/app/communities/community-screens/community-settings/members-settings/members-categories/members-categories-service/members-categories.service';

@Component({
  selector: 'app-category-member-modal',
  templateUrl: './category-member-modal.component.html',
  styleUrls: ['./category-member-modal.component.css']
})
export class CategoryMemberModalComponent implements OnInit {
  public communityCategoryMembersFiltered: IMembersCategories[] = []
  public selected: IMembersCategories[] = []
  public loading: boolean = false;
  public categories: IMembersCategories[] = [];
  toastrService: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { communityId: string },
    private dialogRef: MatDialogRef<CategoryMemberModalComponent>,
    private readonly membersCategoriesService: MembersCategoriesService

  ) {

  }

  ngOnInit(): void {
    this.getMembersCategories();
  }

  async getMembersCategories() {
    try {
      this.loading = true;
      this.communityCategoryMembersFiltered = this.categories = await firstValueFrom(
        this.membersCategoriesService.getCategoryMembersByStatus(
          this.data.communityId,
          'active',
          'active'
        )
      );
    } catch (error) {
      console.error(error);
      this.toastrService.show('Erro ao obter categorias', 'error');
    } finally {
      this.loading = false;
    }
  }

  filterCommunityCategoryMembers(event: any) {
    if (event.target.value.length === 0) {
      this.communityCategoryMembersFiltered = this.categories;
    } else {
      this.communityCategoryMembersFiltered = this.categories.filter((item: IMembersCategories) => item.name.toLowerCase().includes(event.target.value.toLowerCase()));
    }
  }

  verify(item: IMembersCategories): boolean {
    return this.selected.includes(item);
  }

  add(item: IMembersCategories) {
    this.selected.push(item);
  }

  remove(item: IMembersCategories) {
    this.selected.splice(this.selected.indexOf(item), 1);
  }

  closeModal(result: boolean, memberCategories?: IMembersCategories[]) {
    this.dialogRef.close({ result: result, memberCategories });
  }

  handleSelectAll() {
    this.selected = this.communityCategoryMembersFiltered
    if (this.selected.length === 0) return
    this.closeModal(true, this.selected)
  }

  handleSelect() {
    if (this.selected.length === 0) return
    this.closeModal(true, this.selected)
  }

}
