import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { finalize, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface Advertisement {
    id?: number;
    banner: File | string;
    premium: boolean;
    companyName: string;
    description: string;
    contact: string;
    imagesCompany: (File | string)[];
    startDate: string;
    endDate?: string;
    startTime: string;
    endTime?: string;
    category: string;
    region: string;
    approvals?: number;
    favorites?: number;
    createdAt?: string;
    createdBy?: string;
    communityId?: string;
}

export interface Favorite {
    id?: number;
    userId?: string;
    advertisementId?: string
}

@Injectable({
    providedIn: 'root'
})
export class AdvertisementService {
    private baseUrl = environment.eventsApiUrl;
    private advertisementUrl = `${this.baseUrl}/advertisements`;

    constructor(private http: HttpClient) { }

    private prepareFormData(advertisement: Advertisement): FormData {
        const formData = new FormData();

        // Dados do anúncio (exceto os arquivos)
        const advertisementData = {
            premium: advertisement.premium,
            companyName: advertisement.companyName,
            description: advertisement.description,
            contact: advertisement.contact,
            startDate: advertisement.startDate,
            endDate: advertisement.endDate,
            startTime: advertisement.startTime,
            endTime: advertisement.endTime,
            category: advertisement.category,
            region: advertisement.region,
            createdBy: advertisement.createdBy
        };

        formData.append('advertisement', JSON.stringify(advertisementData));

        if (advertisement.banner instanceof File) {
            formData.append('banner', advertisement.banner);
        }

        advertisement.imagesCompany.forEach((image) => {
            if (image instanceof File) {
                formData.append('imagesCompany', image);
            }
        });

        return formData;
    }

    createAdvertisement(advertisement: Advertisement): Observable<HttpResponse<any>> {
        const formData = this.prepareFormData(advertisement);
        return this.http.post<any>(this.advertisementUrl, formData, { observe: 'response' })
            .pipe(finalize(() => this.refreshAdvertisements(advertisement.communityId)));
    }

    updateAdvertisement(id: number, advertisement: Advertisement): Observable<HttpResponse<Advertisement>> {
        return this.http.put<Advertisement>(`${this.advertisementUrl}/${id}`, this.prepareFormData(advertisement), {
            observe: 'response'
        }).pipe(finalize(() => this.refreshAdvertisements(advertisement.communityId)));
    }

    deleteAdvertisement(id: number, communityId: string): Observable<HttpResponse<any>> {
        return this.http.delete(`${this.advertisementUrl}/${id}`, { observe: 'response' })
            .pipe(finalize(() => this.refreshAdvertisements(communityId)));
    }

    getAllAdvertisements(communityId: string): Observable<Advertisement[]> {
        return this.http.get<Advertisement[]>(`${this.advertisementUrl}/${communityId}/all`);
    }

    getAdvertisementById(id: number): Observable<Advertisement> {
        return this.http.get<Advertisement>(`${this.advertisementUrl}/detail/${id}`);
    }

    getFeaturedAdvertisements(communityId: string): Observable<Advertisement[]> {
        return this.http.get<Advertisement[]>(`${this.advertisementUrl}/${communityId}/featured`);
    }

    getOtherAdvertisements(communityId: string): Observable<Advertisement[]> {
        return this.http.get<Advertisement[]>(`${this.advertisementUrl}/${communityId}/others`);
    }

    getUserFavorites(userId: string, communityId: string): Observable<Favorite[]> {
        return this.http.get<Favorite[]>(`${this.advertisementUrl}/user-favorites/${userId}/${communityId}`);
    }

    toggleFavorite(advertisementId: number, userId: string, communityId: string): Observable<boolean> {
        return this.http.post<boolean>(`${this.advertisementUrl}/${advertisementId}/favorite/${userId}/${communityId}`, {});
    }

    getStoreStats(communityId: string): Observable<{ totalAdvertisements: number; premiumAdvertisements: number; totalContacts: number; completedDeals: number }> {
        return this.http.get<{ totalAdvertisements: number; premiumAdvertisements: number; totalContacts: number; completedDeals: number }>(
            `${this.advertisementUrl}/stats/${communityId}`
        );
    }

    refreshAdvertisements(communityId?: string): void {
        if (!communityId) return;
        this.getAllAdvertisements(communityId).subscribe();
        this.getFeaturedAdvertisements(communityId).subscribe();
        this.getOtherAdvertisements(communityId).subscribe();
    }
}
