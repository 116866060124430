import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAnnouncement } from 'src/app/model/announcement.model';

@Injectable({
  providedIn: 'root',
})
export class AnnouncementService {
  baseUrl = environment.eventsApiUrl;
  baseAdmUrl = environment.administradorUrl;
  baseProfileUrl = environment.profileUrl;
  baseUrlCSharp = environment.eventsApiUrlCSharp;
  constructor(
    protected http: HttpClient,
    protected router: Router,
  ) { }

  salvar(announcements: IAnnouncement): Observable<HttpResponse<any>> {
    return this.http.post<IAnnouncement>(`${this.baseUrl}/announcement/salvar-aviso`, announcements, {
      observe: 'response',
    });
    }

  listarAvisosByeventId(eventId: string): Observable<HttpResponse<IAnnouncement[]>> {
    return this.http.get<IAnnouncement[]>(
      `${this.baseUrl}/announcement/listar-avisos-by-event/${eventId}`,
      { observe: 'response' },
    );
  }

  filtrarAvisosByDate(eventId: string, dataStart: Date, dataEnd: Date): Observable<HttpResponse<IAnnouncement[]>> {
    const start = typeof dataStart === 'string' ? new Date(dataStart) : dataStart;
    const end = typeof dataEnd === 'string' ? new Date(dataEnd) : dataEnd;
    
    const formattedStart = start.toISOString().split('T')[0];
    const formattedEnd = end.toISOString().split('T')[0];
  
    const params = new HttpParams()
      .set('dataStart', formattedStart)
      .set('dataEnd', formattedEnd);
  
    return this.http.get<IAnnouncement[]>(
      `${this.baseUrl}/announcement/filter-avisos-by-date/${eventId}`,
      { params, observe: 'response' }
    );
  }

  getSettingsAvisosByeventId(eventId: string): Observable<HttpResponse<IAnnouncement>> {
    return this.http.get<IAnnouncement>(
      `${this.baseUrl}/announcement/get-settings-pagina/${eventId}`,
      { observe: 'response' },
    );
  }

  removerAviso(avisoId:number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/announcement/delete-aviso/${avisoId}`);
  }

}
