import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule, JsonPipe, NgOptimizedImage } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  NativeDateAdapter,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import {
  NgbAccordionModule,
  NgbAlertModule,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbNavModule,
  NgbPopoverModule,
  NgbRatingModule,
  NgbTimepickerModule,
  NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { QRCodeModule } from 'angularx-qrcode';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgToggleModule } from 'ng-toggle-button';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DndModule } from 'ngx-drag-drop';
import { ImageCropperModule } from 'ngx-image-cropper';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgxSimpleTextEditorModule } from 'ngx-simple-text-editor';
import { AparenciaComponent } from '../aparencia/aparencia.component';
import { HttpLoaderFactory } from '../app.module';
import { BellNotificationComponent } from '../bell-notification/bell-notification.component';
import { BusinessTemplatesComponent } from '../business-templates/business-templates.component';
import { CreateTemplateComponent } from '../business-templates/create-template/create-template.component';
import { BusinessComponent } from '../business/business.component';
import { ConfirmaDesvinculoBusinessModalComponent } from '../business/confirma-desvinculo-business-modal/confirma-desvinculo-business-modal.component';
import { SluperCoinModalComponent } from '../business/sluper-coin-modal/sluper-coin-modal.component';
import { VinculaLicencaBusinessModalComponent } from '../business/vincula-licenca-business-modal/vincula-licenca-business-modal.component';
import { CategoryMemberModalComponent } from '../categoryAndChannel/category-member-modal/category-member-modal.component';
import { CommunitieParticipantsModalComponent } from '../categoryAndChannel/communitie-participants-modal/communitie-participants-modal.component';
import { CreateCategoryModalComponent } from '../categoryAndChannel/create-category-modal/create-category-modal.component';
import { CreateChannelCategoryModalComponent } from '../categoryAndChannel/create-channel-category-modal/create-channel-category-modal.component';
import { CreateChannelModalComponent } from '../categoryAndChannel/create-channel-modal/create-channel-modal.component';
import { DeleteSideMenuOptionComponent } from '../categoryAndChannel/delete-side-menu-option/delete-side-menu-option.component';
import { MoveChannelCategoryComponent } from '../categoryAndChannel/move-channel-category/move-channel-category.component';
import { CommunitieParticipantsComponent } from '../communities/communitie-participants/communitie-participants.component';
import { CommunitiesComponent } from '../communities/communities.component';
import { CommunityFavoritesComponent } from '../communities/community-screens/community-favorites/community-favorites.component';
import { CommunityFinanceComponent } from '../communities/community-screens/community-finance/community-finance.component';
import { CommunityManagerDataComponent } from '../communities/community-screens/community-finance/community-manager-data/community-manager-data.component';
import { CommunityManagerFinanceComponent } from '../communities/community-screens/community-finance/community-manager-finance/community-manager-finance.component';
import { CommunityUserFinanceComponent } from '../communities/community-screens/community-finance/community-user-finance/community-user-finance.component';
import { CommunityMentionsComponent } from '../communities/community-screens/community-mentions/community-mentions.component';
import { AddMembersModalComponent } from '../communities/community-screens/community-settings/add-members-modal/add-members-modal.component';
import { ChannelsCategoriesSettingsComponent } from '../communities/community-screens/community-settings/channels-categories-settings/channels-categories-settings.component';
import { CommunitySettingsComponent } from '../communities/community-screens/community-settings/community-settings.component';
import { CreateCategoryMembersModalComponent } from '../communities/community-screens/community-settings/create-category-members-modal/create-category-members-modal.component';
import { InformationSettingsComponent } from '../communities/community-screens/community-settings/information-settings/information-settings.component';
import { EditMemberCategoryComponent } from '../communities/community-screens/community-settings/members-settings/edit-member-category/edit-member-category.component';
import { MembersCategoriesComponent } from '../communities/community-screens/community-settings/members-settings/members-categories/members-categories.component';
import { MembersSettingsComponent } from '../communities/community-screens/community-settings/members-settings/members-settings.component';
import { PostsSettingsComponent } from '../communities/community-screens/community-settings/posts-settings/posts-settings.component';
import { CommunityWarningsComponent } from '../communities/community-screens/community-warnings/community-warnings.component';
import { DeleteWarningModalComponent } from '../communities/community-screens/community-warnings/delete-warning-modal/delete-warning-modal.component';
import { WarningModalPostComponent } from '../communities/community-screens/community-warnings/warning-modal-post/warning-modal-post.component';
import { PeopleLikedPostModalComponent } from '../communities/community-screens/people-liked-post-modal/people-liked-post-modal.component';
import { PostComponentComponent } from '../communities/community-screens/post-component/post-component.component';
import { CommunityStoreComponent } from '../communities/community-store/community-store.component';
import {
  ContactAnnouncementComponent
} from '../communities/community-store/contact-announcement/contact-announcement.component';
import { ManagerCompanyAdvertisementComponent } from '../communities/community-store/manager-company-advertisement/manager-company-advertisement.component';
import { SeeAnnouncementComponent } from '../communities/community-store/see-announcement/see-announcement.component';
import { UserCompanyAdvertisementComponent } from '../communities/community-store/user-company-advertisement/user-company-advertisement.component';
import { ModalBackgroundColorComponent } from '../communities/modal-background-color/modal-background-color.component';
import { ModalCommunityInfoComponent } from '../communities/modal-community-info/modal-community-info.component';
import { ModalCommunityMembersComponent } from '../communities/modal-community-members/modal-community-members.component';
import { ModalCommunityRulesComponent } from '../communities/modal-community-rules/modal-community-rules.component';
import { ModalShowBioComponent } from '../communities/modal-show-bio/modal-show-bio.component';
import { ModalShowRulesComponent } from '../communities/modal-show-rules/modal-show-rules.component';
import { AnswersCommentComponent } from '../communities/post/answers-comment/answers-comment.component';
import { CommentComponent } from '../communities/post/comment/comment.component';
import { ModalDeletePostComponent } from '../communities/post/modal-delete-post/modal-delete-post.component';
import { ModalPostInputComponent } from '../communities/post/modal-post-input/modal-post-input.component';
import { PostComponent } from '../communities/post/post.component';
import { ContaComponent } from '../conta/conta.component';
import { CheckPreferencesModalComponent } from '../contacts-and-connections/check-preferences/check-preferences.component';
import { ContactsAndConnectionsComponent } from '../contacts-and-connections/contacts-sent.component';
import { IndicateResourcesModalComponent } from '../contacts-and-connections/indicate-resources/indicate-resources.component';
import { RemoveConnectionModalComponent } from '../contacts-and-connections/remove-connection/remove-connection.componente';
import { UserPresentationModalComponent } from '../contacts-and-connections/user-presentation/user-presentation.component';
import { DashboardCardNewComponent } from '../dashboard-card-new/dashboard-card-new.component';
import { DashboardCardComponent } from '../dashboard-card/dashboard-card.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { CustomAdapter } from '../datepicker/custom-adapter';
import { CustomDateParserFormatter } from '../datepicker/custom-date-parser';
import { CommunityCardComponent } from '../events/community-card/community-card.component';
import { ConnectionRequestCardComponent } from '../events/connection-request-card/connection-request-card.component';
import { CreateCommunityModalComponent } from '../events/create-community-modal/create-community-modal.component';
import { CreateEventModalComponent } from '../events/create-event-modal/create-event-modal.component';
import { DeleteEventModalComponent } from '../events/delete-event-modal/delete-event-modal.component';
import { EventCardComponent } from '../events/event-card/event-card.component';
import { EventDetailsComponent } from '../events/event-details/event-details.component';
import { EventNpsComponent } from '../events/event-nps/event-nps.component';
import { EventTypeComponent } from '../events/event-type/event-type.component';
import { EventsHeaderComponent } from '../events/events-header/events-header.component';
import { EventsComponent } from '../events/events.component';
import { OtherCommunityCardComponent } from '../events/other-community-card/other-community-card.component';
import { OtherEventsCardComponent } from '../events/other-events-card/other-events-card.component';
import { WelcomeCreateCommunityComponent } from '../events/welcome-create-community/welcome-create-community.component';
import { HomeComponent } from '../home/home.component';
import { ConfirmaDesvinculoModalComponent } from '../licencas/confirma-desvinculo-modal/confirma-desvinculo-modal.component';
import { DadosModalComponent } from '../licencas/dados-modal/dados-modal.component';
import { GerarLicencasModalComponent } from '../licencas/gerar-licencas-modal/gerar-licencas-modal.component';
import { LicencasComponent } from '../licencas/licencas.component';
import { AssignLicenseOwnerComponent } from '../licencas/manage-license-owner-modal/assign-license-owner/assign-license-owner.component';
import { ManageLicensesOwnerModalComponent } from '../licencas/manage-license-owner-modal/manage-license-owner-modal.component';
import { ReplaceLicensesOwnerComponent } from '../licencas/manage-license-owner-modal/replace-licenses-owner/replace-licenses-owner.component';
import { UserSettingsComponent } from '../licencas/manage-license-owner-modal/user-settings/user-settings.component';
import { VinculaLicencaModalComponent } from '../licencas/vincula-licenca-modal/vincula-licenca-modal.component';
import { ExceedPlanResourcesModalComponent } from '../links/exceed-plan-resources/exceeded-plan-resources.component';
import { LinksComponent } from '../links/links.component';
import { LoadingSpinnerComponent } from '../loading-spinner/loading-spinner.component';
import { LoginPreviewComponent } from '../login-preview/login-preview.component';
import { NetworksSelectorComponent } from '../networks-selector/networks-selector.component';
import { PreviewModalComponent } from '../preview-modal/preview-modal.component';
import { ProfilePreviewComponent } from '../profile-preview/profile-preview.component';
import { PublicEventComponent } from '../public-event/public-event.component';
import { SaleDashboardCardComponent } from '../sale-dashboard-card/sale-dashboard-card.component';
import { JoinResellerProgramComponent } from '../sales-dashboard/join-reseller-program/join-reseller-program.component';
import { SalesDashboardComponent } from '../sales-dashboard/sales-dashboard.component';
import { SharedModule } from '../shared/shared.module';
import { SideMenuOptionsComponent } from '../side-menu-options/side-menu-options.component';
import { SideMenuComponent } from '../side-menu/side-menu.component';
import { SluperCoinsHistoryModalComponent } from '../sluper-coins/coins-history-modal/coins-history-modal.component';
import { SLuperCoinsComponent } from '../sluper-coins/sluper-coins.component';
import { UserProfileComponent } from './user-profile.component';

// import { PickerModule } from '@ctrl/ngx-emoji-mart';
const maskConfig: Partial<IConfig> = {
  validation: true, // Isso habilita a validação da máscara
};

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    UserProfileComponent,
    AparenciaComponent,
    LinksComponent,
    ContaComponent,
    ContactsAndConnectionsComponent,
    EventDetailsComponent,
    EventsHeaderComponent,
    EventNpsComponent,
    EventCardComponent,
    EventTypeComponent,
    PostComponent,
    CommentComponent,
    BellNotificationComponent,
    ModalCommunityInfoComponent,
    WelcomeCreateCommunityComponent,
    ModalCommunityRulesComponent,
    ModalBackgroundColorComponent,
    ModalCommunityMembersComponent,
    ModalDeletePostComponent,
    ModalShowRulesComponent,
    ModalShowBioComponent,
    ModalPostInputComponent,
    AnswersCommentComponent,
    OtherEventsCardComponent,
    ConnectionRequestCardComponent,
    CommunityCardComponent,
    OtherCommunityCardComponent,
    DashboardComponent,
    DashboardCardComponent,
    SaleDashboardCardComponent,
    SLuperCoinsComponent,
    SalesDashboardComponent,
    ProfilePreviewComponent,
    NetworksSelectorComponent,
    PreviewModalComponent,
    CreateEventModalComponent,
    CreateCommunityModalComponent,
    DeleteEventModalComponent,
    LoadingSpinnerComponent,
    ExceedPlanResourcesModalComponent,
    IndicateResourcesModalComponent,
    CheckPreferencesModalComponent,
    UserPresentationModalComponent,
    RemoveConnectionModalComponent,
    EventsComponent,
    CommunitiesComponent,
    // CropLinkImageModalComponent,
    SluperCoinsHistoryModalComponent,
    JoinResellerProgramComponent,
    PublicEventComponent,
    HomeComponent,
    BusinessComponent,
    DadosModalComponent,
    ConfirmaDesvinculoModalComponent,
    ConfirmaDesvinculoBusinessModalComponent,
    SluperCoinModalComponent,
    VinculaLicencaBusinessModalComponent,
    GerarLicencasModalComponent,
    ReplaceLicensesOwnerComponent,
    AssignLicenseOwnerComponent,
    LicencasComponent,
    ManageLicensesOwnerModalComponent,
    UserSettingsComponent,
    VinculaLicencaModalComponent,
    BusinessTemplatesComponent,
    CreateTemplateComponent,
    DashboardCardNewComponent,
    LoginPreviewComponent,
    SideMenuComponent,
    CreateChannelCategoryModalComponent,
    CreateCategoryModalComponent,
    CreateChannelModalComponent,
    CommunitieParticipantsComponent,
    CommunitieParticipantsModalComponent,
    SideMenuOptionsComponent,
    DeleteSideMenuOptionComponent,
    MoveChannelCategoryComponent,
    CommunityMentionsComponent,
    CommunityWarningsComponent,
    CommunityFavoritesComponent,
    CommunitySettingsComponent,
    CommunityFinanceComponent,
    PostComponentComponent,
    MembersSettingsComponent,
    ChannelsCategoriesSettingsComponent,
    PostsSettingsComponent,
    InformationSettingsComponent,
    PeopleLikedPostModalComponent,
    CommunityManagerDataComponent,
    CommunityManagerFinanceComponent,
    CommunityUserFinanceComponent,
    CommunityStoreComponent,
    AddMembersModalComponent,
    CreateCategoryMembersModalComponent,
    UserCompanyAdvertisementComponent,
    ManagerCompanyAdvertisementComponent,
    WarningModalPostComponent,
    DeleteWarningModalComponent,
    EditMemberCategoryComponent,
    MembersCategoriesComponent,
    SeeAnnouncementComponent,
    ContactAnnouncementComponent,
    CategoryMemberModalComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatFormFieldModule,
    MatDatepickerModule,
    ImageCropperModule,
    BrowserModule,
    NgxSimpleTextEditorModule,
    PickerModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgApexchartsModule,
    NgbNavModule,
    NgbAccordionModule,
    NgbTypeaheadModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbAlertModule,
    NgbRatingModule,
    MatTooltipModule,
    JsonPipe,
    DndModule,
    QRCodeModule,
    MatMenuModule,
    MatSortModule,
    NgxMaskModule.forRoot(maskConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgSelectModule,
    NgbPopoverModule,
    MatPaginatorModule,
    MatTableModule,
    MatCheckboxModule,
    MatSelectModule,
    ClipboardModule,
    NgbDropdownModule,
    NgxDaterangepickerMd.forRoot(),
    NgToggleModule,
    NgOptimizedImage,
    SharedModule
  ],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: DateAdapter, useClass: NativeDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
  ],
  exports: [
    LoadingSpinnerComponent,
    PreviewModalComponent,
    ProfilePreviewComponent,
    NetworksSelectorComponent,
  ],
})
export class UserProfileModule { }
