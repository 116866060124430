<div class="modal-container">
  <div class="modal-body">
    <section class="event-fields-section">
      <div class="input-container">
        <div class="search-container">
          <label for="searchInput" class="input-label">Adicionar pefis da comunidade: </label>
          <input id="searchInput" maxlength="50" (input)="filterCommunityCategoryMembers($event)"
            placeholder="Procurar perfil" class="form-control" />
        </div>
        <div class="people-container">
          <div class="connection-request-card" *ngFor="let item of communityCategoryMembersFiltered">
            <div class="request-info">
              <div class="info">
                <span class="request-name">{{item.name}}</span>
              </div>
            </div>
            <i *ngIf="verify(item)" class="btn bi bi-check-square-fill quantity-people-button"
              (click)="remove(item)"></i>
            <i *ngIf="!verify(item)" class="btn bi bi-square quantity-people-button" (click)="add(item)"></i>
          </div>
        </div>
      </div>
    </section>
    <div *ngIf="communityCategoryMembersFiltered.length === 0">
      <span class="no-items">Nenhum perfil encontrado</span>
    </div>
  </div>
  <div class="modal-footer">
    <button class="item-delete-button" (click)="closeModal(false)">
      Cancelar
    </button>
    <button class="item-cancel-button" (click)="handleSelectAll()">
      Adicionar Todos
    </button>
    <button class="item-cancel-button" (click)="handleSelect()">
      Adicionar Selecionado
    </button>
  </div>
</div>