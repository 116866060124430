import { IDocumentUserProfileWithPreferences } from "./document-user-profile.model";

export interface IConnection {
  connectionDate: string;
  event?: {
    id: string;
    name: string;
  } | null;
  connection: IDocumentUserProfileWithPreferences;
  indication?: boolean | undefined | null;
  indicatorName?: string | undefined | null;
}

export interface IDisplayedConnection extends IConnection {
  suspended: boolean;
}

export class Connection implements IConnection {
  constructor(
    public connectionDate: string,
    public event: {
      id: string;
      name: string;
    },
    public connection: IDocumentUserProfileWithPreferences,
    public indication?: boolean | undefined | null,
    public indicatorName?: string | undefined | null
  ) { }
}
