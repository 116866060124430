import { ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ApexChart } from 'ng-apexcharts';
import { EventsService } from 'src/app/events/events.service';
import { SeriesData } from 'src/app/model/charts.model';
import { IDashboardCommunity } from 'src/app/model/dashboard-event.model';
import { DashboardCard } from 'src/app/model/dashboard.model';
import { LoadingService } from 'src/app/new-sluper/core/service/loading.service';

@Component({
  selector: 'app-community-manager-data',
  templateUrl: './community-manager-data.component.html',
  styleUrls: ['./community-manager-data.component.css']
})

export class CommunityManagerDataComponent implements OnInit {
  @Output() redirect = new EventEmitter<'home' | 'aparencia' | 'links' | 'opcoes' | 'indicadores' | 'sentContacts' | 'dashboard' | 'salesDashboard' | 'sluperCoins' | 'events' | 'business' | 'businessTemplates' | 'community'>();
  @Input() eventId: string | null = '';

  membersExchangeSeries: SeriesData[] = [{ name: 'Novos membros', data: [] }];
  membersExchangeChart: ApexChart = { type: 'bar', height: 350, redrawOnParentResize: true };
  membersExchangeXAxis = { categories: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul'] };
  membersExchangeColors = ['#2B55E6', '#A078E6'];

  communityEngagementSeries = [180, 17, 3];
  communityEngagementChart: ApexChart = { type: 'radialBar', height: 250, redrawOnParentResize: true };
  communityEngagementLabels = ['Postagens', 'Curtidas', 'Comentários'];
  communityEngagementColors = ['#2B55E6', '#A078E6', '#E64A3F'];

  // userFeedbackSeries = [200, 100, 50];
  // userFeedbackChart: ApexChart = { type: 'donut', height: 300, redrawOnParentResize: true };
  // userFeedbackLabels = ['Gostaram', 'Neutro', 'Não gostaram'];
  // userFeedbackColors = ['#1ACF6C', '#F4D21A', '#EF4848'];
  // userFeedbackLegend = { show: false };

  profileAccessSeries: SeriesData[] = [{ name: 'Acessos', data: [] }];
  profileAccessChart: ApexChart = { type: 'bar', height: 350, redrawOnParentResize: true };
  profileAccessXAxis: { categories: string[] } = { categories: [] };
  profileAccessColors = ['#FFA500'];

  public loading: boolean = false;
  public dashboardCommunity: IDashboardCommunity | null = null;

  dashboardCards:DashboardCard[] = [
    { title: 'Total de membros ativos', icon: 'bi-people', color: '#ff6b6b', key: 'membersActives' },
    { title: 'Porcentagem de membros ativos', icon: 'bi-percent', color: '#81bd86', key: 'activeMembersPercentage' ,isPercent:true},
    { title: 'Novos membros da comunidade', icon: 'bi-people', color: '#ff6b6b', key: 'newCommunityMembers' },
    { title: 'Membros cancelados/desativados', icon: 'bi-person-dash', color: '#ff6b6b', key: 'canceledOrDeactivatedMembers' },
    { title: 'Taxa de rotatividade de membros', icon: 'bi-arrow-repeat', color: '#ff6b6b', key: 'memberChurnRatePercentage' ,isPercent:true},
    { title: 'Membros nunca participaram de eventos', icon: 'bi-calendar-x', color: '#ff6b6b', key: 'membersNeverParticipatedInEvent' },
    { title: 'Membros com mais de 3 meses', icon: 'bi-calendar-range', color: '#81bd86', key: 'membersOverThreeMonthsPercentage',isPercent:true },
    { title: 'Membros nunca postaram', icon: 'bi-chat-left', color: '#ff6b6b', key: 'membersNeverPosted' },
    { title: 'Membros com mais de 5 posts', icon: 'bi-chat-left-text', color: '#81bd86', key: 'membersWithOverFivePostsPercentage' ,isPercent:true},
    { title: 'Comentários/respostas', icon: 'bi-chat-dots', color: '#81bd86', key: 'memberCommentsReplies' },
    { title: 'Indicações', icon: 'bi-share', color: '#81bd86', key: 'indicationComplete' },
    { title: 'Conexões feitas', icon: 'bi-people', color: '#81bd86', key: 'connectionsComplete' },
    { title: 'Inscrições em eventos', icon: 'bi-ticket-perforated', color: '#81bd86', key: 'eventRegistrations' },
  ];

  constructor(
    private eventsService: EventsService,
    private loadingService: LoadingService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.getMetricsDashboardCommunity();
  }

  async getMetricsDashboardCommunity() {
    try{
    this.loadingService.show();
    if (this.eventId) {
      this.eventsService.getEventDashboadCommunity(this.eventId).subscribe(({ body }) => {
        if (body) {
          this.dashboardCommunity = body;
          this.dashboardCommunity.membersActives = this.dashboardCommunity.membersTotal - this.dashboardCommunity.canceledOrDeactivatedMembers;
          this.updateDashboardCards();
          this.updateProfileAccessChart();
          this.updateMembersExchangeChart();
          this.updateCharts();

          this.cdr.detectChanges();
        }
      });
    }
  }catch(error){
    console.error('Erro ao carregar dados: ', error)
  }finally{
    this.loadingService.hide();
  }
  }

  updateDashboardCards() {
    this.dashboardCards = this.dashboardCards.map(card => ({
      ...card,
      percentage: this.dashboardCommunity?.[card.key]?.percentage,
      difference: this.dashboardCommunity?.[card.key]?.difference,
      count: this.dashboardCommunity?.[card.key]?.count != null ? this.dashboardCommunity?.[card.key]?.count : this.dashboardCommunity?.[card.key] || 0
    }));
    this.communityEngagementSeries = this.getEngagementSeries();
  }

  getEngagementSeries() {
    return this.dashboardCommunity?.communityEngagementChart?.data
      ? Object.values(this.dashboardCommunity.communityEngagementChart.data)
      : [0, 0, 0];
  }

  getValueEngagement(key: string) {
    return this.dashboardCommunity?.communityEngagementChart?.data?.[key] || 0;
  }

  getIconClass(key: string): string {
    const icons: Record<string, string> = {
      "Curtidas": "bi bi-hand-thumbs-up",
      "Comentários": "bi bi-chat",
      "Postagens": "bi bi-file-text"
    };
    return icons[key] || "bi bi-bar-chart";
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    setTimeout(() => this.updateCharts(), 300);
  }

  updateMembersExchangeChart() {
    if (this.dashboardCommunity?.membersExchangeChart) {
      const { categories, series } = this.dashboardCommunity.membersExchangeChart;
      this.membersExchangeSeries = Object.keys(series).map(seriesName => ({
        name: seriesName,
        data: series[seriesName].map(Number),
      }));
      this.membersExchangeXAxis = { categories };
    }
  }

  updateProfileAccessChart() {
    if (this.dashboardCommunity?.profileAccessCommunityChart) {
        const { categories, series } = this.dashboardCommunity.profileAccessCommunityChart;
        
        this.profileAccessSeries = Object.keys(series).map(seriesName => ({
            name: seriesName,
            data: series[seriesName].map(Number),
        }));

        this.profileAccessXAxis = { categories };
    }
}


  updateCharts() {
    this.membersExchangeChart = { ...this.membersExchangeChart };
    this.communityEngagementChart = { ...this.communityEngagementChart };
    this.profileAccessChart = { ...this.profileAccessChart };
  }

  redirectToPage(page: 'home' | 'aparencia' | 'links' | 'opcoes' | 'indicadores' | 'sentContacts' | 'dashboard' | 'salesDashboard' | 'sluperCoins' | 'events' | 'business' | 'businessTemplates' | 'community') {
    this.redirect.emit(page);
  }
}