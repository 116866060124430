import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface MetricEvent {
    idCommunity: string;
    idUser: string;
}

@Injectable({
    providedIn: 'root',
})
export class CommunitiesMetricService {
    baseUrl = environment.eventsApiUrl;
    baseAdmUrl = environment.administradorUrl;
    baseProfileUrl = environment.profileUrl;

    constructor(
        protected http: HttpClient,
    ) { }

    sendMetricProfileAccess(metricEvent: MetricEvent): Observable<HttpResponse<void>> {
        return this.http.post<void>(this.baseUrl + '/metricEvent/profile-access', metricEvent, {
            observe: 'response',
        });
    }

    sendMetricLoginRegistrer(metricEvent: MetricEvent): Observable<HttpResponse<void>> {
        return this.http.post<void>(this.baseUrl + '/metricEvent/last-login', metricEvent, {
            observe: 'response',
        });
    }
}