import { Component, Inject, OnInit } from '@angular/core';
import { AnnouncementService } from '../announcement.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastService } from 'src/app/new-sluper/core/service/toast.service';
import { WarningModalPostComponent } from '../warning-modal-post/warning-modal-post.component';

@Component({
  selector: 'app-delete-warning-modal',
  templateUrl: './delete-warning-modal.component.html',
  styleUrls: ['./delete-warning-modal.component.css']
})
export class DeleteWarningModalComponent implements OnInit{

  constructor(private announcementService: AnnouncementService,
            @Inject(MAT_DIALOG_DATA) public data: {
            avisoId: number | null,
            isPageSettings:boolean
          },
        private dialogRef: MatDialogRef<WarningModalPostComponent>,
       private toastrService: ToastService){
    
  }

  ngOnInit(): void {
      
  }

  cancelModal(isSave?:boolean) {
    this.dialogRef.close(isSave);
  }

  deleteAviso(){
    if(!this.data.avisoId){
      const errorMsg ='Aconteceu algum erro ao tentar deletar o aviso!'
      this.toastrService.showError(errorMsg);
      return;
    }
    this.announcementService.removerAviso(this.data.avisoId).subscribe({
      next:(data)=> {
          this.toastrService.showSuccess('Aviso foi deletado com sucesso!');
          this.cancelModal(true);
      },
      error:(err)=>{
        const errorMsg = err ? err : 'Aconteceu algum erro ao tentar deletar o aviso!'
        this.toastrService.showError(errorMsg);
        this.cancelModal(false);

      }
    })
  }
}
