<div class="container-fluid" id="community-warnings">
  <div class="page-title">
    <div class="d-flex align-items-center">
      <i class="bi bi-chevron-left"></i>
      <h4 id="warning-title">Avisos</h4>
    </div>
    <div id="publish-warning__btn-container" *ngIf="isUserCommunityOwner && this.announcements.length > 0">
      <button id="publish-warning__btn" (click)="openWarningPost(false)">Criar Aviso</button>
    </div>
  </div>
  <div id="community-warning-about">
    <h4 *ngIf="titlePage === '' && isUserCommunityOwner">Descrição da página</h4>
    <div class="warning-about-header">
      <h4 *ngIf="titlePage !== ''">{{ titlePage }}</h4>
      <button class="warning-edit-post" *ngIf="isUserCommunityOwner && descriptionPage !== ''" (click)="openWarningPost(true,this.announcementSetting)">
        <i class="bi bi-pencil-square"></i> Editar
      </button>
    </div>
    <p *ngIf="descriptionPage !== ''" [innerHTML]="descriptionPage"></p>

    <p *ngIf="descriptionPage === '' && isUserCommunityOwner">
      Você ainda não criou uma descrição para este quadro de avisos. É importante escrever uma descrição para poder
      orientar melhor sua
      comunidade sobre o que eles podem encontrar nessa página.
    </p>
    <div *ngIf="isUserCommunityOwner">
      <button type="button" *ngIf="titlePage === '' && descriptionPage === ''" class="description-button" (click)="openWarningPost(true)">
        Criar uma descrição agora
      </button>
    </div>
  </div>
  <div *ngIf="announcements.length === 0 && isUserCommunityOwner" class="not-warnings">
    <span>
      Você ainda não criou avisos
    </span>
    <button *ngIf="isUserCommunityOwner" id="publish-warning__btn" (click)="openWarningPost(false)">
      Criar Aviso
    </button>
  </div>
  <div class="warning-posts">
    <section class="post-left" *ngIf="announcements.length == 0"></section>
    <section class="post-left"  *ngIf="announcements.length > 0" >
      <div *ngFor="let month of getAnnouncementsByMonth() | keyvalue">
        <div class="post-month">
          <span>{{ month.key }}</span>
          <div class="line"></div>
        </div>
        <div class="card-item">
          <div *ngFor="let announcement of month.value">
            <div class="warning-item mb-3">
              <div class="warning-header">
                <div class="warning-header-left">
                  <div class="warning-header-info">
                    <span class="warning-header-date">{{ announcement.createDate | date }}</span>
                    <span class="warning-header-title pointer">{{ announcement.title }}</span>
                  </div>
                </div>
                <div class="warning-header-right">
                  <button class="warning-delete-post" *ngIf="isUserCommunityOwner" (click)="openDeleteWarning(announcement.id)">
                    <i class="bi bi-trash-fill"></i>
                  </button>
                  <button class="warning-edit-post" *ngIf="isUserCommunityOwner" (click)="openWarningPost(false,announcement)">
                    <i class="bi bi-pencil-square"></i>
                  </button>
                </div>
              </div>
              <div class="warning-body">
                <p class="warning-text" [innerHTML]="announcement.description"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </section>
    <section class="post-right">
      <div class="post-month">
        <span>Filtrar por:</span>
      </div>
      <div class="personalized-card mb-3">
        <h4 id="warning-title" class="mb-2 mt-0">Data de Avisos</h4>
        <div class="line mb-3"></div>
        <ngx-daterangepicker-material [locale]="calendarLocale" [showCancel]="true"
          (choosedDate)="onDateChosen($event)" (clearClicked)="handleClearDate()" (cancelClicked)="handleClearDate()"></ngx-daterangepicker-material>
      </div>
    </section>
  </div>
</div>
