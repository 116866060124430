<div *ngIf="loading" class="overlay" [style.background]="'#fff'">
  <div class="spinner"></div>
</div>

<div id="login-screen" *ngIf="myCommunity" [ngStyle]="{'background-color': myCommunity ? '#FFFFFF' : '#FFFFFF'}">
  <div id="login-box">
    <header>
      <img [src]="myCommunity ? myCommunity.imageLink : 'assets/images/homem-cadastro.png'" alt="Capa">
    </header>
    <main>
      <form>
        <h3>Login</h3>

        <div class="form-div">
          <label for="user" translate>Email</label>
          <input type="email" name="user" id="user" class="form-input" [formControl]="userEmail" />
        </div>

        <div class="form-div" *ngIf="isVerificationCodeSent">
          <label translate>Código de Verificação</label>
          <app-codigo-verificacao [isJustifyContentEvenly]="true"></app-codigo-verificacao>

          <button class="button-redirect" (click)="reSendVerificationCode()" translate>Reenviar Código</button>
        </div>

        <button type="button" class="btn" [ngStyle]="{'background-color': myCommunity ? '#007BFF' : '#007BFF',
                'color': myCommunity ? '#FFFFFF' : '#FFFFFF',
                'border': '2px solid ' + (myCommunity ? '#007BFF' : '#007BFF')}" (click)="onLogin()" translate>{{
          buttonLabelEntrar }}
        </button>

        <button class="btn-outline" [ngStyle]="{'border': '2px solid ' + (myCommunity ? '#007BFF' : '#007BFF'),
                'color': myCommunity ? '#007BFF' : '#007BFF'}" (click)="onClickRegister()" translate>Cadastre-se
        </button>
      </form>
    </main>
  </div>
</div>


<app-sluper-background *ngIf="!customBg && !myCommunity"
  [removeBackground]="customBg ? true : false"></app-sluper-background>
<app-header-inicial *ngIf="!myCommunity"></app-header-inicial>

<div class="d-flex align-items-start justify-content-center vh-100 container" style="padding-top: 5vh"
  *ngIf="!myCommunity">
  <div class="row w-100 d-flex justify-content-center align-items-center">
    <!-- Coluna para o formulário de login -->
    <div class="col-12 col-md-5 col-sm-12 d-flex flex-column justify-content-center mb-md-0 mb-4">
      <div class="shadow-box">
        <span class="text-normal-32-700 text-white" translate>Acessar</span>
        <div class="form-div">
          <label for="user" class="label-text-inicio" translate>Email</label>
          <input type="email" name="user" id="user" class="form-input col-12 col-sm-12 col-md-10 col-lg-8"
            [formControl]="userEmail" />
        </div>
        <div class="form-div" *ngIf="isVerificationCodeSent">
          <label class="label-text-inicio" translate>Código de Verificação</label>
          <app-codigo-verificacao></app-codigo-verificacao>
          <span class="text-reenviar-codigo d-inline-block mt-1 text-white" (click)="reSendVerificationCode()"
            translate>Reenviar Código</span>
        </div>
        <button type="button" class="btn button-form w-100" (click)="onLogin()">
          <span class="text-normal-16-700 text-white" translate>{{ buttonLabelEntrar }}</span>
        </button>
        <label [hidden]="true" (click)="onClickRegister()" style="color: white">Cadatre-se</label>
      </div>
    </div>

    <!-- Coluna para a imagem e mensagem -->

    <div class="col-12 col-md-7 col-sm-12 d-flex flex-column justify-content-center align-items-end">
      <div class="image-box position-relative">
        <div class="image-content-box">
          <div class="text-image-box">
            <span class="text-normal-40-700 text-white">Ainda não tem Sluper?</span>
            <span class="text-line-height text-white">Não deixe de se conectar com o que importa.</span>
          </div>
          <button type="button" class="btn button-roxo-form w-100" (click)="onClickRegister()">
            <span class="text-normal-16-700 text-white" translate>Cadastre-se</span>
          </button>
        </div>
        <img class="image-cadastro" src="../../../../assets/images/homem-cadastro.png" alt="Anuncio Sluper" />
      </div>
    </div>
  </div>
</div>