<div id="community-manager-data-screen">
    <div class="stats">
        <!-- <div class="stat-item stat-item-highlight">
            <div class="stat-item-left">
                <h3>{{dashboardCommunity?.pointsComplete || 0}}</h3>
                <span>pontos</span>
            </div>
            <div class="stat-item-right">
                <h3>PONTOS</h3>
                <h4>Faça mais conexões para ganhar pontos
                    e troque por benefícios!
                </h4>
                <span>Saiba mais</span>
            </div>
        </div> -->
        <div class="stat-item card-white">
            <div class="stat-item-left">
                <img src="assets/img/store-card-people-icon.png" alt="Ícone de pessoas">
            </div>
            <div class="stat-item-right">
                <span>Total de Membros</span>
                <h4>{{dashboardCommunity?.membersTotal || 0}}</h4>
                <span class="stat-item-link" (click)="redirectToPage('sentContacts')">Ver</span>
            </div>
        </div>
        <div class="stat-item card-white">
            <div class="stat-item-left">
                <img src="assets/img/store-card-people-icon.png" alt="Ícone de pessoas">
            </div>
            <div class="stat-item-right">
                <span>Eventos Criados</span>
                <h4>{{dashboardCommunity?.eventsOrganized || 0}}</h4>
                <span class="stat-item-link" *ngIf="dashboardCommunity?.eventsOrganized || 0 > 0"
                    (click)="redirectToPage('events')">Ver</span>
            </div>
        </div>
        
        <div *ngFor="let card of dashboardCards">
            <app-dashboard-card-new
              class="card-container"
              [dashboardCardData]="{
                title: card.title,
                icon: card.icon,
                color: card.color,
                value: card.count,
                percentage: card.percentage,
                difference: card.difference,
                isPercent: card.isPercent
              }">
            </app-dashboard-card-new>
        </div>
    </div>
    <div class="grid-container">
    <!-- Gráfico de novos membros -->
        <div class="chart-item">
            <h3>Membros da comunidade</h3>
            <div class="divider"></div>
            <apx-chart [series]="membersExchangeSeries" [chart]="membersExchangeChart" [xaxis]="membersExchangeXAxis"
                [colors]="membersExchangeColors"></apx-chart>
        </div>
            <!-- Gráfico de Engajamento em Comunidades -->
            <div class="chart-item">
                <h3>Engajamentos em Comunidades</h3>
                <div class="divider"></div>
                <apx-chart 
                    [series]="communityEngagementSeries" 
                    [chart]="communityEngagementChart"
                    [labels]="communityEngagementLabels" 
                    [colors]="communityEngagementColors">
                </apx-chart>
        <div class="chart-infos">
            <div class="chart-info">
                <div class="info-left">
                    <i class="bi bi-hand-thumbs-up"></i>
                    <span>Curtidas</span>
                </div>
                <div class="info-right">
                    <h5>{{getValueEngagement('likes')}}</h5>
                    <!-- <div class="circle-highlight">
                        <i class="bi bi-arrow-up-short"></i>
                        <span>20%</span>
                    </div> -->
                </div>
            </div>
            <div class="chart-info">
                <div class="info-left">
                    <i class="bi bi-chat"></i>
                    <span>Comentários</span>
                </div>
                <div class="info-right">
                    <h5>{{getValueEngagement('comments')}}</h5>
                    <!-- <div class="circle-highlight">
                        <i class="bi bi-arrow-up-short"></i>
                        <span>10%</span>
                    </div> -->
                </div>
            </div>
            <div class="chart-info">
                <div class="info-left">
                    <i class="bi bi-file-text"></i>
                    <span>Postagens</span>
                </div>
                <div class="info-right">
                    <h5>{{getValueEngagement('posts')}}</h5>
                    <!-- <div class="circle-highlight">
                        <i class="bi bi-arrow-down-short"></i>
                        <span>4%</span>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
<div class="chart-item">
    <h3>Acessos ao Perfil</h3>
    <div class="divider"></div>
    <apx-chart 
        [series]="profileAccessSeries" 
        [chart]="profileAccessChart"
        [xaxis]="profileAccessXAxis"
        [colors]="profileAccessColors">
    </apx-chart>
</div>
    
<div class="user-last-login-list">
    <h3>Últimos Logins</h3>
    <div class="divider"></div>
    <div class="user-list">
        <div class="user-card" *ngFor="let user of dashboardCommunity?.userLastLoginList">
            <img [src]="user.profilePicture || 'assets/img/default-avatar.png'" alt="Foto de {{user.username}}" class="user-avatar">
            <div class="user-info">
                <h4>{{ user.username }}</h4>
                <span>Último login: {{ user.lastLogin | date:'dd/MM/yyyy HH:mm' }}</span>
            </div>
        </div>
    </div>

</div>

<!-- <div class="grid-container full">
    <div class="chart-item">
        <h3>Feedback dos Usuários</h3>
        <div class="divider"></div>
        <div class="chart-row">
            <apx-chart [series]="userFeedbackSeries" [chart]="userFeedbackChart" [legend]="userFeedbackLegend"
                [labels]="userFeedbackLabels" [colors]="userFeedbackColors"></apx-chart>
            <div class="feedback-card-center">
                <div class="feedback-left">
                    <h3>66%</h3>
                    <span>Usuários de sua rede que responderam o questionário</span>
                </div>
                <div class="feedback-right">
                    <div class="circle-highlight">
                        <i class="bi bi-arrow-up-short"></i>
                        <span>10%</span>
                    </div>
                    <span style="color:#24A15C;">+85 <span style="color: #7B7B7B;">usuários comparado ao mês
                            anterior</span></span>
                </div>
            </div>
            <div class="feedback-summary">
                <div class="feedback-summary-item">
                    <div class="summary-left">
                        <div style="background-color: #1ACF6C;"></div>
                        <span>Gostaram</span>
                    </div>
                    <div class="summary-right">
                        <img src="../../../../../assets//img/manager-data-people-icon.png" alt="">
                        <h5>200</h5>
                        <span>66%</span>
                    </div>
                </div>
                <div class="feedback-summary-item">
                    <div class="summary-left">
                        <div style="background-color: #F4D21A;"></div>
                        <span>Neutro</span>
                    </div>
                    <div class="summary-right">
                        <img src="../../../../../assets//img/manager-data-people-icon.png" alt="">
                        <h5>200</h5>
                        <span>66%</span>
                    </div>
                </div>
                <div class="feedback-summary-item">
                    <div class="summary-left">
                        <div style="background-color: #EF4848;"></div>
                        <span>Não gostaram</span>
                    </div>
                    <div class="summary-right">
                        <img src="../../../../../assets//img/manager-data-people-icon.png" alt="">
                        <h5>200</h5>
                        <span>66%</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

