import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxMaskModule } from 'ngx-mask';

import { NgToggleModule } from 'ng-toggle-button';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxSimpleTextEditorModule } from 'ngx-simple-text-editor';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './auth/auth-interceptor.service';
import { } from './events/events-header/events-header.component';
import { CropLinkImageModalComponent } from './links/crop-link-images-modal/crop-link-images-modal.component';
import { PerfilUsuarioModule } from './new-sluper/private/perfil-usuario/perfil-usuario.module';
import { CadastroModule } from './new-sluper/public/cadastro/cadastro.module';
import { LoginModule } from './new-sluper/public/login/login.module';
import { ModalSelectCommunityComponent } from './new-sluper/public/modal-select-community/modal-select-community.component';
import { PostRoutingComponent } from './post-routing/post-routing.component';
import { SanitizeTextPipe } from './sanitizeText/sanitize-text.pipe';
import { InterceptorModule } from './security/http-interceptor.module';
import { TenantInterceptorModule } from './security/tenant-interceptor.module';
import { UserProfileModule } from './user-profile/user-profile.module';

@NgModule({
  declarations:
    [AppComponent,
      CropLinkImageModalComponent,
      ModalSelectCommunityComponent,
      PostRoutingComponent,
      SanitizeTextPipe,
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    UserProfileModule,
    LoginModule,
    CadastroModule,
    PerfilUsuarioModule,
    NgbModule,
    MatDialogModule,
    NgxMaskModule.forRoot(),
    InterceptorModule,
    TenantInterceptorModule,
    NgxSimpleTextEditorModule,
    ImageCropperModule,
    HttpClientModule,
    NgToggleModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: () => localStorage.getItem('authToken'),
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      // Configurações opcionais do toastr
      positionClass: 'toast-top-right',
      timeOut: 5000,
    }),
    NgxDaterangepickerMd.forRoot()

  ],
  providers: [
    MatDialog,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'pt-BR' }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
