<!-- <div class="alert-div">
    <ngb-alert #selfClosingAlert *ngIf="alertMessage" [type]="alertType" (closed)="alertMessage = ''">
        {{ alertMessage }}
    </ngb-alert>
</div> -->
<app-loading-spinner [bgTransparent]="true" *ngIf="loading"></app-loading-spinner>

<div class="post-item">
  <div class="post-header">
    <div class="post-header-left">
      <div class="post-header-img">

        <img [src]="post.user?.profilePicture || 'assets/default-profile.png'"
          [alt]="'Profile picture of ' + (post.user?.name || 'User')" />
      </div>
      <div class="post-header-info">
        <button class="post-header-name pointer" *ngIf="post.user?.name"
          (click)="post.userId && openParticipantProfile($event, post.userId)" [disabled]="!post.userId"
          [attr.aria-label]="'View profile of ' + post.user.name">
          {{ post.user.name }}
        </button>
        <span class="post-header-date">{{ transformDate(post.createdAt) }}</span>
      </div>
    </div>
    <div class="post-header-right">
      <button class="post-header-delete-post" *ngIf="isUserCommunityOwner || userProfile?.idUser === post.userId"
        (click)="openDeleteSidebarItemModal(post.id, 'post')">
        <i class="bi bi-trash-fill"></i>
      </button>
      <button class="post-header-favorite-post" *ngIf="!post.favorite" (click)="favoritePost()">
        <i class="bi bi-bookmark"></i>
      </button>
      <button class="post-header-favorite-post pointer" *ngIf="post.favorite" (click)="unFavoritePost()">
        <img src="assets/img/favorite-post-icon.png" alt="Favorite" />
      </button>
    </div>
  </div>

  <div class="post-body">
    <div class="post-content">
      <div class="post-text-container">
        <!-- <h4 class="post-title">{{ post.title != 'RemoverPost' ? post.title : '' }}</h4> -->
        <p class="post-text" [innerHTML]="sanitizedPost"></p>
      </div>
      <img *ngIf="post.photo && post.photo.length > 0" class="post-image" [src]="post.photo" alt="Imagem do post" />
    </div>

    <div class="post-pdf pointer" *ngIf="post.file" (click)="downloadFile(post.file)">
      <div class="post-pdf-content">
        <span>{{ extractFilenameFromUrl(post.file) }}</span>
        <i id="download" class="bi bi-download"></i>
      </div>
    </div>

    <div class="post-pdf pointer" *ngIf="post.movie">
      <div class="post-pdf-content ratio ratio-16x9">
        <iframe width="100%" height="400" [src]="processVideoUrl(post.movie)" frameborder="0" allowfullscreen></iframe>
      </div>
    </div>
    <span class="post-likes">
      {{ post.likes > 0 ? post.likes + (post.likes === 1 ? ' curtida' : ' curtidas') : '' }}
    </span>

    <div class="post-options">
      <i *ngIf="!post.like" (click)="likePost()" class="bi bi-hand-thumbs-up"></i>
      <i *ngIf="post.like" (click)="unlikeCommentPost(post.like.id, 'post')" class="bi bi-hand-thumbs-up-fill"></i>

      <i (click)="commentId = 0; openModalInputPost()" class="bi bi-chat"></i>
      <i (click)="openModalCommunityMembers(post.id)" class="bi bi-share"></i>
    </div>

    <h4 *ngIf="commentsPost && commentsPost.length > 0" class="post-title mt-2">Comentários</h4>

    <div *ngIf="commentsPost && commentsPost.length > 0" class="comments-list personalized-scroll">
      <app-comment *ngFor="let comment of commentsPost" (answerComment)="answerComment($event)"
        (likeComment)="likeCommentPost($event)" (unlikeComment)="unlikeCommentPost($event)"
        (deleteComment)="deleteComment($event)" [comment]="comment" [isUserCommunityOwner]="isUserCommunityOwner"
        [userProfile]="userProfile" [communityItem]="communityItem" [selectedChannel]="selectedChannel"></app-comment>
    </div>
  </div>
</div>